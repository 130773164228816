// Logos
import technologieLogo from "../assets/images/particpants/yeni-texnoloji-ictimai-birlik.svg";
import digitalAgencyLogo from "../assets/images/particpants/digital-agency.svg";
import computerAcademyLogo from "../assets/images/particpants/computerAcademy.svg";
import designoDragonLogo from "../assets/images/particpants/designoDragon.svg";
import inkStudioLogo from "../assets/images/particpants/ink-studio-logo.svg";

const particpants = [
  {
    id: 1,
    title: "yeni-texnoloji-ictimai-birlik",
    image: technologieLogo,
  },
  {
    id: 2,
    title: "digitalAgencyLogo",
    image: digitalAgencyLogo,
  },
  {
    id: 3,
    title: "computerAcademyLogo",
    image: computerAcademyLogo,
  },
  {
    id: 4,
    title: "designoDragonLogo",
    image: designoDragonLogo,
  },
  {
    id: 5,
    title: "ink-studio-logo",
    image: inkStudioLogo,
  },
];

export default particpants;
