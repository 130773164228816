import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../components/DashboardHeader";
import DashboardPages from "../../../components/DashboardPages";
import MobileNavBar from "../../../components/MobileNavBar";
import SideBar from "../../../components/SideBar";
import { LoginContext } from "../../../utils/Auth";
import SharedProfileModal from "../../../components/SharedProfileModal";
import { motion } from "framer-motion";
import { preloaderTransition } from "../../../animations/animation";
import PreLoader from "../../../animations/PreLoader";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, getUserData } = useContext(LoginContext);
  const [isFirstTime, setIsFirstTime] = useState(false);

  // Check shared user process
  useEffect(() => {
    const checkSharedUser = async () => {
      const sharedUsername = await JSON.parse(
        sessionStorage.getItem("shared-user")
      );
      const addProfileToBoxUsername = await JSON.parse(
        sessionStorage.getItem("addProfileToBoxUsername")
      );
      const sharedToBox = await JSON.parse(
        sessionStorage.getItem("sharedToBox")
      );
      if (sharedUsername !== null) {
        navigate(`/shared-user/${sharedUsername}`);
      }
      if (addProfileToBoxUsername !== null) {
        navigate(`/profile/${addProfileToBoxUsername}`);
      }
      if (sharedToBox && sharedToBox !== null) {
        sessionStorage.removeItem("sharedToBox");
        navigate(`/profile/${sharedToBox}`);
      }
    };
    checkSharedUser();
  }, []);

  useEffect(() => {
    if (user === null) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const localRes = localStorage.getItem("isFirstTime");
      if (localRes || localRes !== null) {
        setIsFirstTime(false);
      } else {
        setIsFirstTime(true);
        localStorage.setItem("isFirstTime", JSON.stringify(false));
      }
    })();
  }, []);

  return (
    <>
      {isFirstTime && (
        <>
          <PreLoader />
          <motion.div
            exit={{ height: "100vh", preloaderTransition }}
            className="preLoadertransition2"
          />
        </>
      )}
      <DashboardHeader />
      <SideBar />
      <DashboardPages />
      <MobileNavBar />
      <SharedProfileModal data={user} />
    </>
  );
};

export default Dashboard;
