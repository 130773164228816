const companiesData = [
  {
    id: 1,
    title: "A",
    companies: [
      {
        id: 1,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 2,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 3,
        title: "Adidas Adidas 3",
      },
      {
        id: 4,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 5,
        title: "Adidas Adidas 5",
      },
      {
        id: 6,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 7,
        title: "Adidas Adidas 7",
      },
      {
        id: 8,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 9,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 10,
        title: "Adidas Adidas 3",
      },
      {
        id: 11,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 12,
        title: "Adidas Adidas 5",
      },
      {
        id: 13,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 14,
        title: "Adidas Adidas 7",
      },
      {
        id: 15,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 16,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 17,
        title: "Adidas Adidas 3",
      },
      {
        id: 18,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 19,
        title: "Adidas Adidas 5",
      },
      {
        id: 20,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 21,
        title: "Adidas Adidas 7",
      },
      {
        id: 22,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 23,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 24,
        title: "Adidas Adidas 3",
      },
      {
        id: 25,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 26,
        title: "Adidas Adidas 5",
      },
      {
        id: 27,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 28,
        title: "Adidas Adidas 7",
      },
      {
        id: 29,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 30,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 31,
        title: "Adidas Adidas 3",
      },
      {
        id: 32,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 33,
        title: "Adidas Adidas 5",
      },
      {
        id: 34,
        title: "A & M Febcon Ltd.",
      },
      {
        id: 35,
        title: "Adidas Adidas 7",
      },
    ],
  },
  {
    id: 2,
    title: "B",
    companies: [
      {
        id: 1,
        title: "Behance",
      },
      {
        id: 2,
        title: "Behance 2",
      },
      {
        id: 3,
        title: "Behance 3",
      },
      {
        id: 4,
        title: "Behance 4",
      },
      {
        id: 5,
        title: "Behance 5",
      },
      {
        id: 6,
        title: "Behance 6",
      },
      {
        id: 7,
        title: "Behance 7",
      },
    ],
  },
  {
    id: 3,
    title: "C",
    companies: [
      {
        id: 1,
        title: "Adidas",
      },
      {
        id: 2,
        title: "Adidas Adidas 2",
      },
      {
        id: 3,
        title: "Adidas Adidas 3",
      },
      {
        id: 4,
        title: "Adidas Adidas 4",
      },
      {
        id: 5,
        title: "Adidas Adidas 5",
      },
      {
        id: 6,
        title: "Adidas Adidas 6",
      },
      {
        id: 7,
        title: "Adidas Adidas 7",
      },
    ],
  },
  {
    id: 4,
    title: "Ç",
    companies: [
      {
        id: 1,
        title: "Behance",
      },
      {
        id: 2,
        title: "Behance 2",
      },
      {
        id: 3,
        title: "Behance 3",
      },
      {
        id: 4,
        title: "Behance 4",
      },
      {
        id: 5,
        title: "Behance 5",
      },
      {
        id: 6,
        title: "Behance 6",
      },
      {
        id: 7,
        title: "Behance 7",
      },
    ],
  },
  {
    id: 5,
    title: "D",
    companies: [
      {
        id: 1,
        title: "Adidas",
      },
      {
        id: 2,
        title: "Adidas Adidas 2",
      },
      {
        id: 3,
        title: "Adidas Adidas 3",
      },
      {
        id: 4,
        title: "Adidas Adidas 4",
      },
      {
        id: 5,
        title: "Adidas Adidas 5",
      },
      {
        id: 6,
        title: "Adidas Adidas 6",
      },
      {
        id: 7,
        title: "Adidas Adidas 7",
      },
    ],
  },
  {
    id: 6,
    title: "E",
    companies: [
      {
        id: 1,
        title: "Behance",
      },
      {
        id: 2,
        title: "Behance 2",
      },
      {
        id: 3,
        title: "Behance 3",
      },
      {
        id: 4,
        title: "Behance 4",
      },
      {
        id: 5,
        title: "Behance 5",
      },
      {
        id: 6,
        title: "Behance 6",
      },
      {
        id: 7,
        title: "Behance 7",
      },
    ],
  },
  {
    id: 7,
    title: "Ə",
    companies: [
      {
        id: 1,
        title: "Əzimli",
      },
    ],
  },
  {
    id: 8,
    title: "F",
    companies: [
      {
        id: 1,
        title: "Ford",
      },
    ],
  },
  {
    id: 9,
    title: "G",
    companies: [
      {
        id: 1,
        title: "Google",
      },
    ],
  },
  {
    id: 10,
    title: "Ğ",
    companies: [],
  },
  {
    id: 11,
    title: "H",
    companies: [
      {
        id: 1,
        title: "Hollywood",
      },
    ],
  },
  {
    id: 12,
    title: "X",
    companies: [
      {
        id: 1,
        title: "Xunark",
      },
    ],
  },
  {
    id: 13,
    title: "I",
    companies: [],
  },
  {
    id: 14,
    title: "İ",
    companies: [
      {
        id: 1,
        title: "İnci",
      },
    ],
  },
  {
    id: 15,
    title: "J",
    companies: [
      {
        id: 1,
        title: "Jalə",
      },
    ],
  },
  {
    id: 16,
    title: "K",
    companies: [
      {
        id: 1,
        title: "Klima",
      },
    ],
  },
  {
    id: 17,
    title: "Q",
    companies: [
      {
        id: 1,
        title: "Adidas",
      },
      {
        id: 2,
        title: "Adidas Adidas 2",
      },
      {
        id: 3,
        title: "Adidas Adidas 3",
      },
      {
        id: 4,
        title: "Adidas Adidas 4",
      },
      {
        id: 5,
        title: "Adidas Adidas 5",
      },
      {
        id: 6,
        title: "Adidas Adidas 6",
      },
      {
        id: 7,
        title: "Adidas Adidas 7",
      },
    ],
  },
  {
    id: 18,
    title: "L",
    companies: [
      {
        id: 1,
        title: "Behance",
      },
      {
        id: 2,
        title: "Behance 2",
      },
      {
        id: 3,
        title: "Behance 3",
      },
      {
        id: 4,
        title: "Behance 4",
      },
      {
        id: 5,
        title: "Behance 5",
      },
      {
        id: 6,
        title: "Behance 6",
      },
      {
        id: 7,
        title: "Behance 7",
      },
    ],
  },
  {
    id: 19,
    title: "M",
    companies: [
      {
        id: 1,
        title: "Miuna",
      },
    ],
  },
  {
    id: 20,
    title: "N",
    companies: [
      {
        id: 1,
        title: "Nike",
      },
    ],
  },
  {
    id: 21,
    title: "O",
    companies: [
      {
        id: 1,
        title: "Opak",
      },
    ],
  },
  {
    id: 22,
    title: "Ö",
    companies: [
      {
        id: 1,
        title: "Ömər",
      },
    ],
  },
  {
    id: 23,
    title: "P",
    companies: [
      {
        id: 1,
        title: "Pandora",
      },
    ],
  },
  {
    id: 24,
    title: "R",
    companies: [
      {
        id: 1,
        title: "Range Rover",
      },
    ],
  },
  {
    id: 25,
    title: "S",
    companies: [
      {
        id: 1,
        title: "Sabah",
      },
    ],
  },
  {
    id: 26,
    title: "Ş",
    companies: [
      {
        id: 1,
        title: "Şimal",
      },
    ],
  },
  {
    id: 27,
    title: "T",
    companies: [
      {
        id: 1,
        title: "Tarlan Alijanov",
      },
    ],
  },
  {
    id: 28,
    title: "U",
    companies: [
      {
        id: 1,
        title: "Adidas",
      },
      {
        id: 2,
        title: "Adidas Adidas 2",
      },
      {
        id: 3,
        title: "Adidas Adidas 3",
      },
      {
        id: 4,
        title: "Adidas Adidas 4",
      },
      {
        id: 5,
        title: "Adidas Adidas 5",
      },
      {
        id: 6,
        title: "Adidas Adidas 6",
      },
      {
        id: 7,
        title: "Adidas Adidas 7",
      },
    ],
  },
  {
    id: 29,
    title: "Ü",
    companies: [
      {
        id: 1,
        title: "Behance",
      },
      {
        id: 2,
        title: "Behance 2",
      },
      {
        id: 3,
        title: "Behance 3",
      },
      {
        id: 4,
        title: "Behance 4",
      },
      {
        id: 5,
        title: "Behance 5",
      },
      {
        id: 6,
        title: "Behance 6",
      },
      {
        id: 7,
        title: "Behance 7",
      },
    ],
  },
  {
    id: 30,
    title: "V",
    companies: [
      {
        id: 1,
        title: "Vulkan",
      },
    ],
  },
  {
    id: 31,
    title: "Y",
    companies: [
      {
        id: 1,
        title: "York",
      },
    ],
  },
  {
    id: 32,
    title: "Z",
    companies: [
      {
        id: 1,
        title: "Zara",
      },
    ],
  },
  {
    id: 33,
    title: "W",
    companies: [
      {
        id: 1,
        title: "Wolt Disney",
      },
    ],
  },
];

export default companiesData;
