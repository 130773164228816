import React from "react";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";

const ProfileSection = ({ customComponent, title, onEdit, showEditButton }) => {
  return (
    <div className="profileSection">
      <div className="topSide">
        <p className="title">{title}</p>
        {showEditButton && (
          <div className="editIcon" onClick={() => onEdit()}>
            <img src={PenIcon} alt="phone_icon" />
          </div>
        )}
      </div>
      <div className="customComponent">{customComponent}</div>
    </div>
  );
};

export default ProfileSection;
