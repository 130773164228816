const useShowHeaderFooter = (pageUrl) => {
  const excludedPaths = [
    "/login",
    "/register",
    "/forgot-password",
    "/send-link",
    "/recover-password",
    "/choose-pack",
    "/dashboard",
    "/search",
    "/search-result",
    "/box-details",
    "/select-pack",
    "/shared-user",
    "/profile",
    "/order-bucard",
    "/settings"
  ];

  return !excludedPaths.some((path) => pageUrl.pathname.startsWith(path));
};

export default useShowHeaderFooter;
