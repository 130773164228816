import React from "react";

const ProfileAbout = ({ profileData }) => {
  return (
    <div className="profileAbout">
      <p className="text">{profileData?.about}</p>
    </div>
  );
};

export default ProfileAbout;
