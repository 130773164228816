const ExperienceImage = ({ char }) => {
  return (
    <div
      className="experienceImage"
      style={{
        width: 56,
        height: 56,
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontSize: 32,
            fontWeight: 500,
            color: "#ffffff",
          }}
        >
          {char?.toUpperCase()}
        </p>
      </div>
    </div>
  );
};

export default ExperienceImage;
