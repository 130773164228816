import {useContext, useEffect} from "react";
import BuyPackModal from "../../../components/BuyPackModal";
import UpgeadeBuisnessPack from "../../../components/UpgeadeBuisnessPack";
import UpgradeFreePack from "../../../components/UpgradeFreePack";
import UpgradePackSubscribe from "../../../components/UpgradePackSubscribe";
import {LoginContext} from "../../../utils/Auth";
import {MainContext} from "../../../utils/MainContext";
import {PROFILE_TYPES} from "../../Profile/Service/ProfileService";

const UpgradePack = () => {
  const { user } = useContext(LoginContext);
  const { setDashboardPage } = useContext(MainContext);

  useEffect(() => {
    if (user?.profile?.profile_type === PROFILE_TYPES.business) {
      setDashboardPage("boxes");
    }
  }, []);

  return (
    <div className="upgradePack">
      {user?.profile?.profile_type === PROFILE_TYPES.free && (
        <>
          <UpgradeFreePack />
          {/* <UpgeadeBuisnessPack /> */}
        </>
      )}
      {/* {user?.profile?.profile_type === PROFILE_TYPES.personal && (
        <UpgeadeBuisnessPack />
      )} */}
      <UpgradePackSubscribe />
      <BuyPackModal
        userId={user?.profile?.id}
      />
    </div>
  );
};

export default UpgradePack;
