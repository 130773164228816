import React from "react";

const ProfileKeywords = ({ profileData }) => {
  return (
    <div className="profileKeywords">
      {profileData?.keywords?.map((item) => (
        <div className="keywordCard" key={item.id}>
          <p>{item.keyword}</p>
        </div>
      ))}
    </div>
  );
};

export default ProfileKeywords;
