import React from "react";

const QRCode = ({ width, height, className, qrCodeData }) => {
  return (
    <div
      className={`qrCode ${className && className}`}
      style={{
        width: width || "100%",
        height: height || "100%",
        background: "#F1F5FE80",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <div
        style={{ transform: "scale(1.4)", marginTop: 10 }}
        dangerouslySetInnerHTML={{ __html: qrCodeData }}
      />
    </div>
  );
};

export default QRCode;
