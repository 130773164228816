import React, {useState} from "react";
import Switch from "./Switch";

const ProfileStatus = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  return (
    <section className="profileStatus">
      <div className="contanier">
        <div className="row">
          <h2>Profile Status</h2>
          <div className="statusComp">
            <div className="statusInfo">
              <h2>Private Account</h2>
              <Switch isChecked={isChecked} onToggle={handleToggle} />
            </div>
            <div className="statusText">
              <p>
                When your account is public, your profile and posts can be seen
                by anyone, on or off Bucard even if they don't have an Bucard
                account. When your account is private, only the followers that
                you approve can see what you share, including your photos or
                videos on hashtag and location pages, and your followers and
                following lists. Learn more
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileStatus;
