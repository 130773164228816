import {useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import backArrow from "../../../assets/images/icons/back-arrow.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import MobileLanguageSelect from "../../../components/MobileLanguageSelect";
import {MainContext} from "../../../utils/MainContext";
import RecoverForm from "./RecoverForm";
import {t} from "i18next";

const RecoverPassword = () => {
  // Global states
  const { setPhone, mobileLangVisivle } = useContext(MainContext);
  const params = useParams();

  // Router
  const navigate = useNavigate();

  // Reset input
  useEffect(() => {
    setPhone("");
  }, [setPhone, navigate]);

  return (
    <main>
      <section className="recoverPage">
        <div className="screen">
          <div className="leftSide">
            <div className="formHeader">
              <div
                className="navigate"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={backArrow} alt="go-back" />
                <p className="navigateInfo">{t("forgotPassword.goBack")}</p>
              </div>
              <LanguageSelector />
            </div>
            <div className="formSteps">
              <RecoverForm data={params} />
            </div>
          </div>
          <div className="rightSide"></div>
        </div>
        {mobileLangVisivle && <MobileLanguageSelect />}
      </section>
    </main>
  );
};

export default RecoverPassword;
