import {useNavigate} from "react-router-dom";

const CompanyPage = () => {
  const navigate = useNavigate();

  return (
    <section className="companyPage">
      <h2>Cooming soon...</h2>
      <p onClick={() => navigate(-1)}>Go back</p>
    </section>
  );
};

export default CompanyPage;
