const emojies = [
  {
    codes: "1F603",
    char: "😃",
    name: "grinning face with big eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F923",
    char: "🤣",
    name: "rolling on the floor laughing",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F604",
    char: "😄",
    name: "grinning face with smiling eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F601",
    char: "😁",
    name: "beaming face with smiling eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F606",
    char: "😆",
    name: "grinning squinting face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F605",
    char: "😅",
    name: "grinning face with sweat",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F602",
    char: "😂",
    name: "face with tears of joy",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F642",
    char: "🙂",
    name: "slightly smiling face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F643",
    char: "🙃",
    name: "upside-down face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1FAE0",
    char: "🫠",
    name: "melting face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F609",
    char: "😉",
    name: "winking face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F60A",
    char: "😊",
    name: "smiling face with smiling eyes",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F600",
    char: "😀",
    name: "grinning face",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F607",
    char: "😇",
    name: "smiling face with halo",
    category: "Smileys & Emotion (face-smiling)",
    group: "Smileys & Emotion",
    subgroup: "face-smiling",
  },
  {
    codes: "1F970",
    char: "🥰",
    name: "smiling face with hearts",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F60D",
    char: "😍",
    name: "smiling face with heart-eyes",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F929",
    char: "🤩",
    name: "star-struck",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F618",
    char: "😘",
    name: "face blowing a kiss",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F617",
    char: "😗",
    name: "kissing face",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F61A",
    char: "😚",
    name: "kissing face with closed eyes",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F619",
    char: "😙",
    name: "kissing face with smiling eyes",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F972",
    char: "🥲",
    name: "smiling face with tear",
    category: "Smileys & Emotion (face-affection)",
    group: "Smileys & Emotion",
    subgroup: "face-affection",
  },
  {
    codes: "1F60B",
    char: "😋",
    name: "face savoring food",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F61B",
    char: "😛",
    name: "face with tongue",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
  {
    codes: "1F61C",
    char: "😜",
    name: "winking face with tongue",
    category: "Smileys & Emotion (face-tongue)",
    group: "Smileys & Emotion",
    subgroup: "face-tongue",
  },
];

export default emojies;
