import { Link } from "react-router-dom";

import { useContext } from "react";

// Local data
import socialBtns from "../db/socialBtns";

// Images
import closeModal from "../assets/images/icons/close-modal.svg";
import copyIcon from "../assets/images/icons/copy-icon.svg";

// Components
import bIcon from "../assets/images/icons/b-icon.svg";
import staticBanner from "../assets/images/static-banner.svg";

// Context
import { MainContext } from "../utils/MainContext";

// Translation
import { useTranslation } from "react-i18next";

// Toast alert
import { $api } from "../api/api";
import { toastMsg } from "../utils/swal";
import QRCode from "./QRCode";
import { PROFILE_TYPES } from "../constants/constants";

const SharedProfileModal = ({ data }) => {
  // Translation
  const { t } = useTranslation();

  const { sharedProfileModal, setSharedProfileModal } = useContext(MainContext);

  // Copy link to clipboard
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      return false;
    }
  };

  // Create url for copy to clipboard
  const handleCopyClick = () => {
    const url = `${process.env.REACT_APP_PAGE_URL}/shared-user/${data?.username}`;
    if (copyToClipboard(url)) {
      toastMsg("Link kopyalandı", "#00ff00", "#fff");
    } else {
      toastMsg("Kopyalama zamanı xəta", "#ff0000", "#fff");
    }
  };

  const qrCode = data?.profile?.qr?.svg;

  return (
    <div
      className={
        sharedProfileModal ? "sharedProfileModal active" : "sharedProfileModal"
      }
    >
      <div
        className="modalOverlay"
        onClick={() => setSharedProfileModal(false)}
      ></div>
      <div className="modalBox">
        <div className="seperator"></div>
        <div className="addProfileHead">
          <div className="close" onClick={() => setSharedProfileModal(false)}>
            <img src={closeModal} alt="close-icon" />
          </div>
        </div>
        <div className="profileInfo">
          <div className="cardTop">
            <div
              className={`profileImg ${
                data?.profile?.profile_type === PROFILE_TYPES.personal &&
                "goldBrd"
              }`}
              // className="profileImg"
            >
              {data?.profile?.avatar ? (
                <img
                  src={`${
                    data?.profile?.avatar.includes("api.")
                      ? ""
                      : $api("image_url") + "/"
                  }${data?.profile?.avatar}`}
                  alt={data?.full_name}
                />
              ) : (
                <img src={bIcon} alt="b-icon" />
              )}
              {(data?.profile?.profile_type === PROFILE_TYPES.personal ||
                data?.profile?.profile_type === PROFILE_TYPES.business) && (
                <span className="badge">Pro</span>
              )}
            </div>
            <div className="profileBanner">
              <img
                src={
                  data?.profile?.background_image
                    ? `${
                        data?.profile?.background_image.includes("api.")
                          ? ""
                          : $api("image_url") + "/"
                      }${data?.profile?.background_image}`
                    : staticBanner
                }
                alt={data?.profession?.name}
              />
            </div>
          </div>
          <div className="cardBottom">
            <div className="cardTitle">
              <div className="title">
                <p className="fullName">{data?.full_name}</p>
                <p className="inBoxCount">
                  {data?.profile?.in_boxes_count} qutuda
                </p>
                {/* {data.plan === "COMPANY" && (
                  <span className="badge">{t("profileCard.company")}</span>
                )} */}
              </div>
            </div>
            <div className="cardInfo">
              <div className="info">
                <p className="address">{data?.profile?.address}</p>
                <Link className="phone" to={`tel:${data?.phone_number}`}>
                  {data?.phone_number}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="qrCode">
          <QRCode qrCodeData={qrCode} />
        </div>
        {false && (
          <ul className="shareSocial">
            {socialBtns.map((item) => (
              <li className="socialButton" key={item.id}>
                {/* <Link
                to={`${item.url}  Menimle bu link uzerinden elaqe saxlaya bilersiz. https://bucard.az/shared-user/${data?.username}`}
                target="_blank"
              >
                <img src={item.icon} alt={item.title} />
              </Link> */}
                <Link to={item.url} target="_blank">
                  <img src={item.icon} alt={item.title} />
                </Link>
              </li>
            ))}
          </ul>
        )}
        <div className="link" onClick={() => setSharedProfileModal(false)}>
          <Link
            to={`https://bucard.az/shared-user/${data?.username}`}
            className="sharedLink"
            target="_blank"
          >
            bucard.az/<span>{data?.username}</span>
          </Link>
          <img
            src={copyIcon}
            alt="copy"
            className="copy"
            onClick={() => {
              handleCopyClick();
              setSharedProfileModal(false);
            }}
          />
        </div>
        <div
          className="closeModal"
          onClick={() => setSharedProfileModal(false)}
        >
          <img src={closeModal} alt="close-icon" />
          <span>{t("addProfileModal.close")}</span>
        </div>
      </div>
    </div>
  );
};

export default SharedProfileModal;
