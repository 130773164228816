import * as ProfileProvider from "../Provider/ProfileProvider";

export const PROFILE_TYPES = {
  free: "free",
  personal: "personal",
  business: "business",
};

export const profileFindById = async (id) => {
  return await ProfileProvider.getProfileFindById(id).then((res) => {
    return res.data;
  });
};

export const profileFindByUsername = async (username) => {
  return await ProfileProvider.getProfileFindByUsername(username).then(
    (res) => {
      return res.data;
    }
  );
};

export const profileSkills = async () => {
  return await ProfileProvider.getProfileSkills().then((res) => {
    return res.data;
  });
};

export const userEdit = async (payload) => {
  return await ProfileProvider.patchUserEdit(payload).then((res) => {
    return res.data;
  });
};

export const profileUpdate = async (payload) => {
  return await ProfileProvider.putProfileUpdate(payload).then((res) => {
    return res.data;
  });
};

export const profileEdit = async (payload) => {
  return await ProfileProvider.putProfileEdit(payload).then((res) => {
    return res.data;
  });
};

export const deleteProfileDetail = async (payload) => {
  return await ProfileProvider.deleteProfileDetailById(payload).then((res) => {
    return res.data;
  });
};

export const keywordsAllList = async (payload) => {
  return await ProfileProvider.getKeywordsAllList(payload).then((res) => {
    return res.data;
  });
};

export const skillsAllList = async (payload) => {
  return await ProfileProvider.getSkillsAllList(payload).then((res) => {
    return res.data;
  });
};

export const languagesAllList = async (payload) => {
  return await ProfileProvider.getLanguagesAllList(payload).then((res) => {
    return res.data;
  });
};

export const professionAllList = async (payload) => {
  return await ProfileProvider.getProfessionAllList(payload).then((res) => {
    return res.data;
  });
};

export const sendOtpToPhone = async (payload) => {
  return await ProfileProvider.postSendOtpToPhone(payload).then((res) => {
    return res.data;
  });
};

export const otpConfirmation = async (payload) => {
  return await ProfileProvider.postOtpConfirmation(payload).then((res) => {
    return res.data;
  });
};

export const sendOtpToEmail = async (payload) => {
  return await ProfileProvider.getSendOtpToEmail(payload).then((res) => {
    return res.data;
  });
};

export const urlMetaDatas = async (payload) => {
  return await ProfileProvider.postUrlMetaData(payload).then((res) => {
    return res.data;
  });
};
