import {$api} from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const getBucardStylesList = async () => {
  return await $axios.get($api("order_bucard_get_card_styles"));
};

export const postBucardOrderCard = async (payload) => {
  return await $axios.post($api("order_bucard_order_card"), payload);
};

export const getBucardValidateCardOrder = async () => {
  return await $axios.get($api("order_bucard_validate_card_order"));
};
