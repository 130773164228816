import React from "react";
import { Link, useLocation } from "react-router-dom";

const Verification = () => {
  const location = useLocation();
  const [unused, type, status] = location.search
    .replaceAll("?type", "")
    .replaceAll("?status", "")
    .split("=");

  return (
    <section className="verification">
      <div className="container">
        <div className="row">
          <div className="verificationContainer">
            <h2 className="infoText">
              {type === "email" ? "Elektron poçtun" : "Telefon nömrəsinin"}{" "}
              təsdiqlənməsi {status === "failed" ? "uğursuz" : "uğurlu"} oldu.
            </h2>
            {/* <Link to={"/"}>Ana səhifə</Link> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Verification;
