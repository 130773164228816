const Checkbox = ({ checked, onChange }) => {
  return (
    <label className={`customCheckbox ${checked ? "checked" : ""}`}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="checkMark"></span>
    </label>
  );
};

export default Checkbox;
