import ChangePlan from "./ChangePlan";
import Hero from "./Hero";
// import Statistic from "./Statistic";
import Packets from "./Packets";

// import Banner from "./Banner";

const Home = () => {
  return (
    <main>
      <Hero />
      <ChangePlan />
      {/* <Statistic /> */}
      <Packets />
      {/* <Banner /> */}
    </main>
  );
};

export default Home;
