import {$api} from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const getBoxes = async () => {
  return await $axios.get($api("boxes_get_all"));
};

export const getBoxDetails = async (id) => {
  return await $axios.get($api("boxes_get_details", { id }));
};

export const postBoxCreate = async (payload) => {
  return await $axios.post($api("boxes_create"), payload);
};

export const putBoxEdit = async (payload, id) => {
  return await $axios.put($api("boxes_edit", { id }), payload);
};

export const deleteBoxWithId = async (id) => {
  return await $axios.delete($api("boxes_delete", { id }));
};

export const postAddProfileToBox = async (id, body) => {
  return await $axios.post($api("boxes_add_profile", { id }), body);
};

export const postDeleteProfileInBox = async (id, body) => {
  return await $axios.post($api("boxes_remove_profile", { id }), body);
};

export const postMoveBetweenBoxes = async (payload) => {
  return await $axios.post($api("boxes_move"), payload);
};
