import React, { useState } from "react";
import * as ProfileService from "../../Service/ProfileService";
import CustomInput from "../../../../components/CustomInput";
import Spinner from "../../../../components/Spinner";
import Swal from "sweetalert2";

const ProfileEmailEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_email: profileData?.extra_emails || [],
  });
  const [newEmail, setNewEmail] = useState({
    email: "",
    isVerified: false,
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      let res = null;
      if (!profileData?.email && profileData?.extra_emails?.length === 0) {
        res = await ProfileService.profileEdit({
          email: newEmail.email,
        });
      } else {
        res = await ProfileService.profileEdit({
          extra_email: [{ email: newEmail.email }],
        });
      }
      setProfileData((prev) => ({
        ...prev,
        extra_emails: res?.extra_emails,
      }));
      Swal.fire(
        "Uğurlu",
        "Email əlavə olundu. Zəhmət olmasa sizə gələn mail üzərindən təsdiqləmə linkinə keçid edin.",
        "success"
      );
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeEmail = async (email) => {
    Swal.fire({
      title: "Email silmək",
      text: "Bu emaili silməyə əminsinizmi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "extra_email",
            id: email.id,
          });
          setProfileData((prev) => ({
            ...prev,
            extra_emails: res.data.extra_emails,
          }));
          setProfileInfo((prev) => ({
            ...prev,
            extra_email: res.data.extra_emails,
          }));
          Swal.fire("Uğurlu", "Email silindi", "success");
          closeModal();
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        Swal.fire("İmtina edildi", "Email silinmədi", "error");
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileEmailEdit">
      <div className="editEmailArea">
        {profileInfo.extra_email.map((email) => (
          <button
            key={email.id}
            className="phoneCaption"
            onClick={() => removeEmail(email)}
          >
            {email.email}
            <span>x</span>
          </button>
        ))}
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="infoEmailForm">
        <CustomInput
          label={"Yeni elektron poçt"}
          placeholder="info@bucard.az"
          errorMessage={errorMessage}
          value={newEmail.email}
          onChange={(value) => {
            const email = value.trim();
            setNewEmail({ email });
            if (email === "") {
              setErrorMessage("Elektron poçt daxil edin");
            } else {
              setErrorMessage("");
            }
          }}
        />
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          disabled={errorMessage !== ""}
        >
          Yadda saxla
        </button>
      </div>
    </div>
  );
};

export default ProfileEmailEdit;
