import React from "react";
import lngIcon from "../../../../assets/images/icons/world-language-icon.svg";

const ProfileLanguages = ({ profileData }) => {
  return (
    <div className="profileLanguages">
      {profileData?.languages?.map((item) => (
        <div className="lngCard" key={item.id}>
          <img
            src={
              `https://flagsapi.com/${
                item?.code?.toUpperCase() === "EN"
                  ? "GB"
                  : item?.code?.toUpperCase()
              }/flat/64.png` || lngIcon
            }
            alt="lng_icon"
          />
          <p>{item.name}</p>
        </div>
      ))}
    </div>
  );
};

export default ProfileLanguages;
