import React, { useState } from "react";
// import plusIcon from "../../../../assets/images/icons/plus-circle.svg";
// import arrowLeft from "../../../../assets/images/icons/arrow-square-left.svg";
// import arrowRight from "../../../../assets/images/icons/arrow-square-right.svg";
import threeDotIcon from "../../../../assets/images/icons/more-horizontal.svg";
import profileBgImg from "../../../../assets/images/profile-bakground-image.svg";
import PenIcon from "../../../../assets/images/icons/edit-2.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { t } from "i18next";
import * as ProfileService from "../../Service/ProfileService";
import { confirmSwal } from "../../../../utils/swal";

const ProfileFeaturedSection = ({
  profileData,
  onAdd,
  onEdit,
  showAddIcon,
  showEditIcon,
  isOwner,
  setProfileData,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const removeFeatured = (item) => {
    confirmSwal(
      item.title,
      "Silmək istədiyinizə əminsinizmi ?",
      "Bəli",
      "Xeyr",
      () => {},
      async () => {
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "featured_link",
            id: item.id,
          });
          setProfileData(res.data);
        } catch (err) {
          console.log(err);
        }
      },
      "Silinmə tamamlandı",
      "İmtina"
    );
  };

  return (
    <div className="profileFeaturedSection">
      <div className="topSide">
        <div className="leftSide">
          <p className="title">{t("profileHero.portfolio")}</p>
          {/* {showAddIcon && <div className="addIcon" onClick={() => onAdd()}>
                <img src={plusIcon} alt="plus_icon"/>
                <span>Add new</span>
            </div>} */}
        </div>
        <div className="rightSide">
          {showAddIcon && isOwner && (
            <div className="editIcon" onClick={() => onAdd()}>
              <img src={PenIcon} alt="phone_icon" />
            </div>
          )}
          {/* <div className="paginationIcon">
            <img src={arrowLeft} alt="arrow_left_icon" />
          </div>
          <div className="paginationIcon">
            <img src={arrowRight} alt="arrow_right_icon" />
          </div> */}
        </div>
      </div>
      <div className="bottomSide">
        <Swiper
          slidesPerView={2}
          slidesPerGroup={2}
          // loopAdditionalSlides={3}
          loop={true}
          spaceBetween={16}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            280: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
          }}
        >
          {profileData?.featured_links?.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="slideItem">
                <Link to={item.url} className="slideImg">
                  <img
                    src={
                      item.image_url !== "https://placekitten.com/200/300"
                        ? item.image_url
                        : profileBgImg || profileBgImg
                    }
                    alt={item.title}
                  />
                </Link>
                <div className="info">
                  <div className="title">
                    <p>{item.title}</p>
                    {showEditIcon && isOwner && (
                      <div
                        className="options"
                        onClick={() => {
                          if (
                            selectedItem === null ||
                            selectedItem?.id === ""
                          ) {
                            setSelectedItem(item);
                          } else {
                            setSelectedItem(null);
                          }
                        }}
                      >
                        <img src={threeDotIcon} alt="three_dot_icon" />
                        <ul
                          className={`optionMenu ${
                            item.id === selectedItem?.id && "isOpen"
                          }`}
                        >
                          <li
                            className="optionMenuItem"
                            onClick={() => onEdit(item)}
                          >
                            Düzəliş et
                          </li>
                          <li
                            className="optionMenuItem del"
                            onClick={() => removeFeatured(item)}
                          >
                            Sil
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <p className="desc">{item.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProfileFeaturedSection;
