const placeholders = [
    { title: "Designer" },
    { title: "Frontend" },
    { title: "Backend" },
    { title: "Full Stack" },
    { title: "Doctor" },
    { title: "Police" },
    { title: "Project manager" },
    
  ];
  export default placeholders;