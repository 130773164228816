import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import BuyPackModal from "../../../components/BuyPackModal";
import LanguageSelector from "../../../components/LanguageSelector";
import Loading from "../../../components/Loading";
import PacketCard from "../../../components/PacketCard";
import * as HomeService from "../../Home/Service/HomeService";
import FreeTrial from "../../../components/FreeTrial";

const SelectPack = () => {
  // Translation
  const { t } = useTranslation();

  const { userId, profile_type } = useParams();

  // Local state
  const [anually, setAnually] = useState(false);

  // Scroll page when entered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      try {
        const res = await HomeService.planAllList();
        setPlanList(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);

  const onSubmitFreeTrial = async () => {
    setLoading(true);
    try {
      await HomeService.freeTrial();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <section className="choosePack">
        {loading && <Loading />}
        <div className="container">
          <div className="sectionHead">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="bucard-logo" />
              </Link>
            </div>
            <LanguageSelector />
          </div>
          <div className="sectionBody">
            <div className="packetsHead">
              <div className="sectionInfo">
                <h2 className="packetsTitle">{t("choosePack.title")}</h2>
                <p className="packetsInfo">{t("choosePack.info")}</p>
              </div>
              <div className="btnGroup">
                <button
                  className={anually ? "tabBtn" : "tabBtn active"}
                  onClick={() => setAnually(false)}
                >
                  {t("packets.month")}
                </button>
                <button
                  className={anually ? "tabBtn active" : "tabBtn"}
                  onClick={() => setAnually(true)}
                >
                  {t("packets.year")}
                </button>
              </div>
            </div>
            <div className="selectPacket">
              {planList
                .filter((plan) => plan.profile_type !== "business")
                .map((item) => (
                  <PacketCard
                    id={item.id}
                    data={item}
                    key={item.id}
                    state={anually}
                    profile_type={profile_type}
                  />
                ))}
              <FreeTrial onClick={onSubmitFreeTrial} />
            </div>
            <BuyPackModal userId={userId} profile_type={profile_type} />
          </div>
        </div>
      </section>
    </main>
  );
};

export default SelectPack;
