import React, { useState } from "react";
import * as ProfileService from "../../Service/ProfileService";
// import CustomInput from "../../../../components/CustomInput";
import OtpForm from "../../../../components/OtpForm";
import Spinner from "../../../../components/Spinner";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";

const ProfilePhoneNumbersEdit = ({
  profileData,
  closeModal,
  setProfileData,
}) => {
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    extra_phone_number: profileData?.extra_phone_numbers || [],
    phone_number: profileData?.phone_number || [],
  });

  const [newPhoneNumber, setNewPhoneNumber] = useState({
    phone_number: "",
    isVerified: false,
  });

  const [isEditPrimaryPhone, setIsEditPrimaryPhone] = useState(false);

  const { t } = useTranslation();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (newPhoneNumber.isVerified) {
        await ProfileService.profileEdit(profileInfo);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    const phoneNumber = value?.trim();
    setNewPhoneNumber({ phone_number: phoneNumber });
  };

  const verifyPhone = async (e, phone) => {
    setLoading(true);
    e.preventDefault();
    const body = {
      extra_phone_number: phone ? phone : newPhoneNumber.phone_number,
    };
    try {
      if (body.extra_phone_number !== 0 || phone) {
        if (!phone) {
          await ProfileService.profileEdit({
            extra_phone_number: [
              phone ? { phone_number: phone } : newPhoneNumber,
            ],
          });
        } else {
          setNewPhoneNumber({ phone_number: phone });
        }
        const res = await ProfileService.sendOtpToPhone(body);
        if (res.message === "Successfully sent.") {
          setShowOtpModal(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removePhone = async (phone) => {
    Swal.fire({
      title: "Nömrəni silmək",
      text: "Nömrə silinsin?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          const res = await ProfileService.deleteProfileDetail({
            field_name: "extra_phone_number",
            id: phone.id,
          });
          setProfileData((prev) => ({
            ...prev,
            extra_phone_numbers: res.data.extra_phone_numbers,
          }));
          setProfileInfo((prev) => ({
            ...prev,
            extra_phone_number: res.data.extra_phone_numbers,
          }));
          Swal.fire("Uğurlu", "Nömrə silindi", "success");
          closeModal();
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        Swal.fire("İmtina edildi", "Nömrəni silməkdən imtina edildu", "error");
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return showOtpModal ? (
    <OtpForm
      onSubmit={(verified) => {
        if (verified) {
          closeModal();
          window.location.reload();
        }
      }}
      phone={`${newPhoneNumber.phone_number}`}
    />
  ) : (
    <div className="profilePhoneEdit">
      <div className="editPhoneArea">
        {profileInfo.extra_phone_number.map((phone) => (
          <button
            key={phone.id}
            className="phoneCaption"
            onClick={() => removePhone(phone)}
          >
            {phone.phone_number}
            {!phone.is_verified && (
              <span
                className="verifyBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  verifyPhone(e, phone.phone_number);
                }}
              >
                Təsdiqlə
              </span>
            )}
            <span>x</span>
          </button>
        ))}
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="infoPhoneForm">
        <div className="phoneBox">
          <label htmlFor="phone">{t("login.phone")} *</label>
          <PhoneInput
            international
            defaultCountry="AZ"
            value={newPhoneNumber?.phone_number}
            onChange={handleChange}
            limitMaxLength={19}
            disabled={isEditPrimaryPhone}
            style={{ opacity: isEditPrimaryPhone ? 0.5 : 1 }}
          />
        </div>
      </form>
      <div className="btnGroup">
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button
          className={`btn ${errorMessage && "id-disabled"}`}
          onClick={newPhoneNumber.isVerified ? onSubmit : verifyPhone}
          disabled={errorMessage !== ""}
        >
          Yadda saxla
        </button>
      </div>
    </div>
  );
};

export default ProfilePhoneNumbersEdit;
