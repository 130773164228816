import {$api} from "../../../api/api";
import $axios from "../../../api/axiosAccessor";

export const postRegister = async (payload) => {
  return await $axios.post($api("user_register"), payload);
};

export const postRegisterOtp = async (payload) => {
  return await $axios.post($api("user_register_confirmation"), payload);
};

export const postUserRefreshToken = async (payload) => {
  return await $axios.post($api("user_refresh_token"), payload);
};

export const patchUserCreateUsername = async (payload) => {
  return await $axios.patch($api("user_update_single"), payload);
};

export const putActivateUser = async (payload) => {
  return await $axios.put($api("user_register_activate_user"), payload);
};

export const postResendOtp = async (payload) => {
  return await $axios.post($api("user_register_resend_otp"), payload);
};

export const postResetPassword = async (payload) => {
  return await $axios.post($api("user_send_reset_password_otp"), payload);
};

export const postSetNewPassword = async (payload) => {
  return await $axios.post($api("user_set_new_password"), payload);
};

export const postConfirmResetPassword = async (payload) => {
  return await $axios.post($api("user_confirm_reset_password"), payload);
};

export const putChangePassword = async (payload) => {
  return await $axios.put($api("user_change_password"), payload);
};

export const postUserLogin = async (payload) => {
  return await $axios.post($api("user_login"), payload);
};

export const getUserDetails = async () => {
  return await $axios.get($api("user_get"));
};

export const getUserProfileDetails = async () => {
  return await $axios.get($api("user_get_profile"));
};

export const putUpdateUsername = async (payload) => {
  return await $axios.put($api("user_update_single"), payload);
};
