import React from 'react';

const Switch = ({ isChecked, onToggle }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={onToggle} />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;
