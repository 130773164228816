import axios from "axios";

const $axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // DON'T CHANGE THIS and set REACT_APP_API_URL in .env file
});

const doNotSendTokenList = [
  "/api/v1/user/register/",
  "/api/v1/user/confirm-registration/",
  "/api/v1/user/login/",
  "/api/v1/user/refresh/",
  "/api/v1/user/register/resend-otp/",
  "/api/v1/user/send-reset-password-otp/",
  "/api/v1/user/confirm-reset-password/",
  "/api/v1/user/set-new-password/",
];

$axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("X-Authorization");
    if (!doNotSendTokenList.includes(config.url) && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default $axios;
