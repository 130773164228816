import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import plusCircle from "../../../assets/images/icons/plus-circle.svg";
import settings from "../../../assets/images/icons/settings.svg";
import AddNewBoxModal from "../../../components/AddNewBoxModal";
import Box from "../../../components/Box";
import EditBoxNameModal from "../../../components/EditBoxNameModal";
import Loading from "../../../components/Loading";
import MobileBox from "../../../components/MobileBox";
import { LoginContext } from "../../../utils/Auth";
import { MainContext } from "../../../utils/MainContext";
import * as DashboardService from "../Service/DashboardService";
import * as HomeService from "../../Home/Service/HomeService";
import { toastMsg } from "../../../utils/swal";
import Swal from "sweetalert2";

const Boxes = () => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { user } = useContext(LoginContext);
  const { setAddBoxOpen, sideBarOpen, setSideBarOpen, boxes, setBoxes } =
    useContext(MainContext);

  // Detect device width for render box component
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Local states
  const [openBoxId, setOpenBoxId] = useState(null);
  const [loading, setLoading] = useState(false);

  const openBoxMenu = (boxId) => {
    if (openBoxId === boxId) {
      setOpenBoxId(null);
    } else {
      setOpenBoxId(boxId);
    }
  };

  useEffect(() => {
    const getBoxes = async () => {
      setLoading(true);
      try {
        const res = await DashboardService.getBoxesList();
        setBoxes(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getBoxes();
  }, []);

  const onSubmitFreeTrial = async () => {
    setLoading(true);
    try {
      const res = await HomeService.freeTrial();
      if (res?.trial_end_date) {
        await Swal.fire(t("freeTrialCard.trialStarted"), "", "success").then(
          () => {
            window.location.reload();
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      sessionStorage.removeItem("trial");
      setLoading(false);
    }
  };

  const checkIsTrialSelected = async () => {
    const isTrial = await JSON.parse(sessionStorage.getItem("trial"));
    if (isTrial !== null && isTrial) {
      onSubmitFreeTrial();
    } else {
      return;
    }
  };

  useEffect(() => {
    checkIsTrialSelected();
  }, []);

  return (
    <div className="dashboardBoxes">
      {loading && <Loading />}
      <div className="dashboardTitle">
        <div
          className="mobileSettings"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <img src={settings} alt="setting-icon" />
        </div>
        <h2 className="sectionTitle">{t("allBoxes.title")}</h2>
        <div className="addIcon" onClick={() => setAddBoxOpen(true)}>
          <img src={plusCircle} alt="plus-icon" />
        </div>
      </div>
      <div className="boxes">
        <div
          className={`outsideClickOverlay ${openBoxId && "isOpen"}`}
          onClick={() => setOpenBoxId(null)}
        ></div>
        <div className="box addBox" onClick={() => setAddBoxOpen(true)}>
          <p className="boxTitle">{t("allBoxes.create")}</p>
          <div className="addIcon">
            <img src={plusCircle} alt="plus-icon" />
          </div>
        </div>
        {windowWidth <= 768
          ? boxes.map((item) => (
              <MobileBox
                openBoxId={openBoxId}
                setOpenBoxId={setOpenBoxId}
                openBoxMenu={openBoxMenu}
                data={item}
                key={item.id}
              />
            ))
          : boxes.map((item) => (
              <Box
                openBoxId={openBoxId}
                setOpenBoxId={setOpenBoxId}
                openBoxMenu={openBoxMenu}
                data={item}
                key={item.id}
              />
            ))}
      </div>
      <AddNewBoxModal />
      <EditBoxNameModal userData={user} />
    </div>
  );
};

export default Boxes;
