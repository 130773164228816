import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import leftArrow from "../../../assets/images/icons/arrow-circle-left.svg";
import AddProfileModal from "../../../components/AddProfileModal";
import DashboardHeader from "../../../components/DashboardHeader";
import Loading from "../../../components/Loading";
import ProfileCard from "../../../components/ProfileCard";
import {confirmSwal} from "../../../utils/swal";
import * as DashboardService from "../Service/DashboardService";
import {MainContext} from "../../../utils/MainContext";
import SharedProfileModal from "../../../components/SharedProfileModal";
import {LoginContext} from "../../../utils/Auth";

const BoxDetails = () => {
  // Translation
  const { t } = useTranslation();

  // Router
  const { boxID } = useParams();

  // Global states
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
  const { boxes } = useContext(MainContext);
  const { user } = useContext(LoginContext);

  //   Local states
  const [box, setBox] = useState({
    id: "",
    title: "",
    icon: "",
    owner: {
      id: "",
      full_name: "",
      phone_number: "",
      username: "",
      email: "",
      avatar: "",
      background_image: "",
      bio: "",
      in_boxes_count: 0,
    },
    profiles: [],
  });
  const [loading, setLoading] = useState(false);

  const deleteProfileFromBox = async (id) => {
    const body = { profile: id };
    setLoading(true);
    try {
      const res = await DashboardService.removeProfileInBox(box.id, body);
      setBox((prev) => ({
        ...prev,
        profiles: res.data.profiles,
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const removeUserFromBox = (id) => {
    confirmSwal(
      "İstifadəçi silmək",
      "İstifadəçi qutudan silinsin ?",
      t("allBoxes.grant"),
      t("allBoxes.deny"),
      () => {},
      () => deleteProfileFromBox(id),
      "İstifadəçi qutudan silindi",
      "İsitfadəçini silməkdən imtina etdiniz"
    );
  };

  const boxDetails = async () => {
    setLoading(true);
    try {
      const res = await DashboardService.getBox(boxID);
      setBox(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    boxDetails();
  }, [boxID]);


  return (
    <>
      <DashboardHeader />
      <main>
        {loading && <Loading />}
        <section className="boxDetails">
          <div className="profiles">
            <div className="topBar">
              <div className="boxName">
                <Link className="goBack" to="/dashboard">
                  <img src={leftArrow} alt="left-arrow" />
                  <span>{t("boxDetails.goBack")}</span>
                </Link>
                <p className="title">
                  {box.icon} {box.title}
                </p>
              </div>
              <div className="boxOption">
                {box.profiles?.length !== 5 &&
                  boxes
                    .map((item) => item.profiles_count)
                    .reduce((acc, curr) => acc + curr, 0) !== 0 && (
                    <button
                      className="addBtn"
                      onClick={() => setProfileModalIsOpen(true)}
                    >
                      + {t("boxDetails.add")}
                    </button>
                  )}
              </div>
            </div>
            <div className="profilesInfo">
              <div className="users">
                {box.profiles?.length !== 0 ? (
                  box.profiles.map((profile) => (
                    <ProfileCard
                      data={profile}
                      inBox={true}
                      key={profile.id}
                      add={() => {}}
                      remove={() => removeUserFromBox(profile.id)}
                    />
                  ))
                ) : (
                  <div className="emptyBox">
                    <p className="info">{t("boxDetails.noUser")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="boxOptionRes">
            <button
              className="addBtn"
              onClick={() => setProfileModalIsOpen(true)}
            >
              + {t("boxDetails.add")}
            </button>
          </div> */}
        </section>
      </main>
      <AddProfileModal
        profileModalIsOpen={profileModalIsOpen}
        setProfileModalIsOpen={setProfileModalIsOpen}
        boxDetails={box.profiles}
        boxId={box.id}
      />
      <SharedProfileModal data={user} />
    </>
  );
};

export default BoxDetails;
