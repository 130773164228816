const Button = ({
  children,
  className,
  value,
  onClick,
  disabled,
  id,
  type,
}) => {
  return (
    <button
      className={className}
      value={value}
      onClick={onClick}
      disabled={disabled}
      id={id}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
