import * as HomeProvider from "../Provider/HomeProvider";

export const planAllList = async () => {
  return await HomeProvider.getPlanAllList().then((res) => {
    return res.data.data;
  });
};

export const freeTrial = async () => {
  const payload = {
    trial_duration: 14,
  };
  return await HomeProvider.postFreeTrial(payload).then((res) => {
    return res.data;
  });
};
