import {useContext, useState} from "react";

// Images
import closeModal from "../assets/images/icons/close-modal.svg";

// FakeDB
import emoji from "../db/emoji";

// Context
import {MainContext} from "../utils/MainContext";

// Yup
import {object, string} from "yup";

// React hook form
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

// Translation
import {useTranslation} from "react-i18next";

import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";

const AddNewBoxModal = () => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { addBoxOpen, setAddBoxOpen, setBoxes } = useContext(MainContext);

  // Local states
  const [emojiActive, setEmojiActive] = useState({
    codes: "1F603",
    char: "😃",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Select emoji
  const selectEmoji = (emojiItem) => {
    if (emojiActive === emojiItem.codes) {
      setEmojiActive(null);
    } else {
      setEmojiActive(emojiItem);
    }
  };

  // Validate modal input
  const inputSchema = object({
    title: string()
      .required(t("addNewBoxModal.errorMsg1"))
      .trim()
      .min(2, t("addNewBoxModal.errorMsg2"))
      .max(16, t("addNewBoxModal.errorMsg3")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(inputSchema),
  });

  const onSubmit = async (data) => {
    reset();
    setLoading(true);
    const body = {
      title: data.title,
      icon: emojiActive.char,
    };
    try {
      const res = await DashboardService.createBox(body);
      setBoxes((prev) => [...prev, res]);
      setAddBoxOpen(false);
    } catch (err) {
      console.log(err);
      setErrorMessage("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={addBoxOpen ? "addNewBoxModal active" : "addNewBoxModal"}>
      <div className="modalOverlay" onClick={() => setAddBoxOpen(false)}></div>
      <div className="modalBox">
        <div className="seperator"></div>
        <div className="modalHead">
          <h2 className="modalTitle">{t("addNewBoxModal.title")}</h2>
          <div className="close" onClick={() => setAddBoxOpen(false)}>
            <img src={closeModal} alt="close-icon" />
          </div>
        </div>
        <ul className="emojiList">
          {emoji.map((item) => (
            <li
              className={
                emojiActive.codes === item.codes
                  ? "emojiItem isActive"
                  : "emojiItem"
              }
              key={item.codes}
              onClick={() => selectEmoji(item)}
            >
              {item.char}
            </li>
          ))}
        </ul>
        <form className="modalForm" onSubmit={handleSubmit(onSubmit)}>
          <input
            className="modalInput"
            type="text"
            name="title"
            placeholder={t("addNewBoxModal.placeholder")}
            {...register("title")}
          />
          {errors.title && (
            <span className="errorMessage">{errors.title.message}</span>
          )}
          <button type="submit" className="addBox">
            {t("addNewBoxModal.add")}
          </button>
        </form>
      </div>
      <div className="closeModal" onClick={() => setAddBoxOpen(false)}>
        <img src={closeModal} alt="close-icon" />
        <span>{t("addNewBoxModal.close")}</span>
      </div>
    </div>
  );
};

export default AddNewBoxModal;
