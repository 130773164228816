// instrument.js
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

// Initialize Sentry
Sentry.init({
  dsn: "https://37f1e8e7f75fb6de97be810cbdfbea45@o4508302523236352.ingest.de.sentry.io/4508302525136976",
  integrations: [
    // React Router Integration for tracing
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, // Capture 100% of transactions for tracing
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/], // Customize trace propagation
  replaysSessionSampleRate: 0.1, // Capture replay for 10% of sessions
  replaysOnErrorSampleRate: 1.0, // Capture replay for 100% of sessions with errors
});
