import React from "react";
import {Link, useParams} from "react-router-dom";
import {paymentInfoTypes} from "../../../constants/constants";

const PaymentInfo = () => {
  const { type } = useParams();

  return (
    <section className="paymentInfo">
      <div className="container">
        <div className="row">
          {type === paymentInfoTypes.success ? (
            <div className="infoCard">
              <h2 className="success">Ödənişiniz uğurla tamamlandı</h2>
              <p>
                Ödənişin təsdiqlənməsi üçün zəhmət olmasa yenidən <Link to="/login">daxil olun</Link>
              </p>
            </div>
          ) : type === paymentInfoTypes.cancel ? (
            <div className="infoCard">
              <h2 className="cancel">Ödənişdən imtina etdiniz</h2>
              <p>
                <Link to="/">Ana səhifə</Link>
              </p>
            </div>
          ) : (
            <div className="infoCard">
              <h2 className="decline">Ödəniş uğursuz oldu</h2>
              <p>
                <Link to="/">Ana səhifə</Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PaymentInfo;
