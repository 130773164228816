import React from 'react'
import azFlag from "../../../assets/images/icons/flagAze.svg";
import enFlag from "../../../assets/images/icons/flagEng.svg";
import ruFlag from "../../../assets/images/icons/rusFlag.svg";

const Language = () => {
  return (
    <section className="language">
        <div className="contanier">
            <div className="row">
                Language
                <div className="langSelect">
                    <div className="azLang changeLang">
                        <div className="aze">
                        <img src={azFlag} alt="" />
                        Azerbaijan
                        </div>
                      
                        <div className="circle">
                        </div>
                    </div>
                    <div className="enFlag changeLang">
                        <div className="eng">
                        <img src={enFlag} alt="" />
                        English

                        </div>
                        <div className="circle"></div>
                    </div>

                    <div className="ruFlag changeLang">
                        <div className="rus">
                        <img src={ruFlag} alt="" />
                        Russian

                        </div>
                    <div className="circle"></div>
                    </div>
                    <button>Save</button>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Language