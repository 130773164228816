import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import settings from "../../../assets/images/icons/settings.svg";
import DashboardHeader from "../../../components/DashboardHeader";
import Loading from "../../../components/Loading";
import MobileNavBar from "../../../components/MobileNavBar";
import OrderBucardModal from "../../../components/OrderBucardModal";
import SideBar from "../../../components/SideBar";
import {MainContext} from "../../../utils/MainContext";
import {PROFILE_TYPES} from "../../Profile/Service/ProfileService";
import * as OrderBucardService from "../Service/OrderBucardService";

const OrderBucard = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cardId, setCardId] = useState("");
  const [isOrderCardModalOpen, setIsOrderCardModalOpen] = useState(false);

  const { setSideBarOpen, sideBarOpen } = useContext(MainContext);

  const [cardStyles, setCardStyles] = useState([]);

  useEffect(() => {
    const bucardStyles = async () => {
      setLoading(true);
      try {
        const res = await OrderBucardService.bucardStylesList();
        setCardStyles(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    bucardStyles();
  }, []);

  const onClickCard = (item) => {
    if (selectedCard) {
      setSelectedCard(null);
    } else {
      setSelectedCard(item);
    }
    setCardId(item.id);
  };

  return (
    <div className="orderBucard">
      {loading && <Loading />}
      <SideBar />
      <DashboardHeader />
      <MobileNavBar />
      <div className="dashboardTitle">
        <div
          className="mobileSettings"
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <img src={settings} alt="setting-icon" />
        </div>
        <h2 className="sectionTitle">{t("orderBucard.title")}</h2>
      </div>
      <div className="cardList">
        {cardStyles.map((item) => {
          if (item?.name?.toLowerCase() !== PROFILE_TYPES.business) {
            return (
              <div
                className={`card ${
                  selectedCard?.id === item.id && "selectedCard"
                }`}
                key={item.id}
                onClick={() => onClickCard(item)}
              >
                <img
                  src={
                    selectedCard?.id === item.id
                      ? item.card_back
                      : item.card_front
                  }
                />
                <button
                  className={`btn ${selectedCard?.id === item.id && "active"}`}
                  onClick={() => setIsOrderCardModalOpen(true)}
                >
                  {t("orderBucard.order")}
                </button>
              </div>
            );
          }
        })}
      </div>
      <div
        className={`overlay ${selectedCard && "active"}`}
        onClick={() => setSelectedCard(null)}
      ></div>
      <OrderBucardModal
        isOpen={isOrderCardModalOpen}
        setIsOpen={setIsOrderCardModalOpen}
        cardId={cardId}
      />
    </div>
  );
};

export default OrderBucard;
