import React from "react";

const ProfileSkills = ({ profileData }) => {
  return (
    <div className="profileSkills">
      {profileData?.skills?.map((item) => (
        <div className="skillCard" key={item.id}>
          <p>{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default ProfileSkills;
