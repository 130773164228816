import React, {useContext, useState} from 'react';
import ArrowRight from "../../../assets/images/icons/arrow-right.svg";
import {MainContext} from '../../../utils/MainContext';
import General from './General';
import ContactInfo from './ContactInfo';

const Account = () => {
    const { settingsServiceComponent, setSettingsServiceComponent } = useContext(MainContext);
    const [isSelected, setIsSelected] = useState(false);

    return (
        <section className="account">
            <div className="container">
                <div className="row">
                    {!isSelected ? (
                        <>
                            <h2>Account</h2>
                            <p>View or update your account details</p>
                            <div className="accountComponents">
                                <div className="general accountItem" onClick={() => {
                                    setSettingsServiceComponent('general');
                                    setIsSelected(true);
                                }}>
                                    <span>General</span>
                                    <img src={ArrowRight} alt="Arrow Right" />
                                </div>
                                <div className="contact accountItem" onClick={() => {
                                    setSettingsServiceComponent('contact');
                                    setIsSelected(true);
                                }}>
                                    <span>Contact Information</span>
                                    <img src={ArrowRight} alt="Arrow Right" />
                                </div>
                            </div>
                        </>
                    ) : (
                        settingsServiceComponent === 'general' ? (
                            <General setIsSelected={setIsSelected} />
                        ) : settingsServiceComponent === 'contact' ? (
                            <ContactInfo setIsSelected={setIsSelected} />
                        ) : null
                    )}
                </div>
            </div>
        </section>
    );
}

export default Account;

