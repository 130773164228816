import React from "react";

const ProfileEditModal = ({ modalData, closeModal }) => {
  return (
    <div className="profileEditModal">
      <div className="overlay" onClick={() => closeModal()}></div>
      <div className="content">
        <p className="modalTitle">{modalData.title}</p>
        {modalData.customComponent}
      </div>
    </div>
  );
};

export default ProfileEditModal;
