import * as SearchProvider from "../Provider/SearchProvider";

export const searchByKeyword = async (payload) => {
  return await SearchProvider.searchProfile(payload).then((res) => {
    return res.data;
  });
};

export const saveSearch = async (payload) => {
  return await SearchProvider.postSaveSearch(payload).then((res) => {
    return res.data;
  });
};
