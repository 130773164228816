import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import addCircle from "../../../../assets/images/icons/add-circle.svg";
import * as ProfileService from "../../Service/ProfileService";
import Spinner from "../../../../components/Spinner";
import Close from "../../../../assets/images/icons/close-square.svg";
import {SOCIAL_MEDIA_TYPES} from "../../../../constants/constants";
import {v4 as uuidv4} from "uuid";

const ProfileSocialEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    social_media: profileData?.social_media || [],
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit({
        social_media: profileInfo.social_media.filter(
          (item) => item.url !== ""
        ),
      });
      setProfileData((prev) => ({
        ...prev,
        social_media: res.social_media,
      }));
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addNewUrl = () => {
    setProfileInfo((prev) => ({
      ...prev,
      social_media: [
        ...prev.social_media,
        {
          id: uuidv4(),
          profile: "",
          url: "",
        },
      ],
    }));
  };

  const removeUrl = async (input) => {
    setLoading(true);
    try {
      if (input.id && input.url) {
        const res = await ProfileService.deleteProfileDetail({
          field_name: "social_media",
          id: input.id,
        });
        setProfileData((prev) => ({
          ...prev,
          social_media: res?.data?.social_media,
        }));
        setProfileInfo((prev) => ({
          ...prev,
          social_media: res?.data?.social_media,
        }));
      } else {
        setProfileInfo((prev) => ({
          ...prev,
          social_media: prev.social_media.filter((item) => item !== input),
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (value, input) => {
    const findedType = Object.values(SOCIAL_MEDIA_TYPES).find((item) =>
      String(value).toLowerCase().includes(item.toLowerCase())
    );
    const updated = profileInfo.social_media.map((item) => {
      if (input.id === item.id) {
        return {
          ...item,
          url: value,
          type: item.type || findedType,
        };
      } else {
        return item;
      }
    });
    setProfileInfo((prev) => ({
      ...prev,
      social_media: updated,
    }));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileSocialEdit">
      <form className="infoSocialForm">
        {profileInfo.social_media.map((item) => (
          <div className="socialInp" key={item.id}>
            <CustomInput
              placeholder={"Link daxil edin"}
              onChange={(value) => handleChangeInput(value, item)}
              value={item.url}
            />
            <button
              type="button"
              onClick={() => removeUrl(item)}
              className="deleteBtn"
            >
              <img src={Close} alt="close_icon" />
            </button>
          </div>
        ))}
      </form>
      <div className="btnGroup">
        <div className="addBtn">
          <button className="btnAdd" type="button" onClick={addNewUrl}>
            <img src={addCircle} alt="add_icon" />
            Əlavə et
          </button>
        </div>
        <button
          className="btn cancel"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button disabled={false} className="btn" onClick={onSubmit}>
          Yadda saxla
        </button>
      </div>
    </div>
  );
};

export default ProfileSocialEdit;
