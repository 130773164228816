import badgeOne from "../assets/images/profile-badge-1.png";
import badgeTwo from "../assets/images/profile-badge-2.png";
import badgeThree from "../assets/images/profile-badge-3.png";
import badgeFour from "../assets/images/profile-badge-4.png";
import badgeFive from "../assets/images/profile-badge-5.png";

const homeProfileBadges = [
  {
    id: 1,
    image: badgeOne,
  },
  {
    id: 2,
    image: badgeTwo,
  },
  {
    id: 3,
    image: badgeThree,
  },
  {
    id: 4,
    image: badgeFour,
  },
  {
    id: 5,
    image: badgeFive,
  },
];

export default homeProfileBadges;
