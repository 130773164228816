import * as OrderBucardProvider from "../Provider/OrderBucardProvider";

export const bucardStylesList = async () => {
  return await OrderBucardProvider.getBucardStylesList().then((res) => {
    return res.data;
  });
};

export const bucardOrderCard = async (payload) => {
  return await OrderBucardProvider.postBucardOrderCard(payload).then((res) => {
    return res.data;
  });
};

export const bucardValidateCardOrder = async () => {
  return await OrderBucardProvider.getBucardValidateCardOrder().then((res) => {
    return res.data;
  });
};
