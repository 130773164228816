import React, { useEffect, useState } from "react";
import * as ProfileService from "../../Service/ProfileService";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import Spinner from "../../../../components/Spinner";
import * as DictionaryService from "../../../Dictionaries/Service/DictionaryService";
import { toastMsg } from "../../../../utils/swal";
import CreatableSelect from "react-select/creatable";

const ProfileSkillsEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    skills: profileData?.skills || [],
  });
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const getSkillsList = async () => {
      setLoading(true);
      try {
        const res = await DictionaryService.allSkills();
        setSkills(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getSkillsList();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit(profileInfo);
      setProfileData(res);
      setProfileInfo((prev) => ({
        ...prev,
        skills: res,
      }));
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeInp = async (input) => {
    setLoading(true);
    try {
      if (input.id) {
        await ProfileService.deleteProfileDetail({
          field_name: "skill",
          id: input.id,
        });
        const updated = profileInfo.skills.filter(
          (item) => item.id !== input.id
        );
        setProfileInfo((prev) => ({
          ...prev,
          skills: updated,
        }));
      } else {
        const updated = profileInfo.skills.filter(
          (item) => item.title.toLowerCase() !== input.title.toLowerCase()
        );
        setProfileInfo((prev) => ({
          ...prev,
          skills: updated,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async () => {
    setLoading(true);
    const missingKeywords = profileData.skills.filter((skill) => {
      return !profileInfo.skills.some(
        (item) => item.title.toLowerCase() === skill.title.toLowerCase()
      );
    });
    console.log("missingKeywords", missingKeywords);
    try {
      if (missingKeywords.length !== 0) {
        await ProfileService.profileEdit({
          skills: [...profileInfo.skills, ...missingKeywords],
        });
      }
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileSkillsEdit">
      <div className="infoSkillsEdit">
        <div className="editSkillsArea">
          {profileInfo.skills.map((skill) => (
            <button
              key={skill.id}
              className="skillsCaption"
              onClick={() => removeInp(skill)}
            >
              {skill.title}
              <span>x</span>
            </button>
          ))}
        </div>
        <div className="addSkillsArea">
          <CreatableSelect
            placeholder={"Seçin..."}
            closeMenuOnSelect={true}
            controlShouldRenderValue={false}
            escapeClearsValue={true}
            hideSelectedOptions={true}
            options={skills}
            onChange={(selected) => {
              const existing = profileInfo.skills.some(
                (item) => item.id === selected.value
              );
              if (!existing) {
                setProfileInfo((prev) => ({
                  ...prev,
                  skills: [
                    ...prev.skills,
                    {
                      id: selected.value,
                      title: selected.label,
                    },
                  ],
                }));
              } else {
                console.log("if1");
                toastMsg(
                  "Bu bacarıq artıq əlavə olunub !",
                  "#ffffff",
                  "#000000"
                );
              }
            }}
            isDisabled={profileInfo.skills.length >= 20}
            onCreateOption={(inputValue) => {
              const skillExist = profileInfo.skills.some(
                (item) => item.title.toLowerCase() === inputValue.toLowerCase()
              );
              if (!skillExist) {
                setProfileInfo((prev) => ({
                  ...prev,
                  skills: [
                    ...prev.skills,
                    {
                      title: inputValue,
                    },
                  ],
                }));
              } else {
                console.log("if2");
                toastMsg(
                  "Bu bacarıq artıq əlavə olunub !",
                  "#ffffff",
                  "#000000"
                );
              }
            }}
          />
          {/* {profileInfo.skills.map((skill, index) => (
            <div key={skill.id} className="socialInp">
              <CustomInput
                placeholder={"Type"}
                rightIcon={plusIcon}
                onChange={(value) => handleInputChange(index, value)}
                value={skill.title}
              />
              <span onClick={() => removeInp(skill)}>x</span>
            </div>
          ))} */}
          <div className="infoSkills">
            <img src={infoIcon} alt="Info Icon" />
            Sadəcə 20 bacarıq əlavə edə bilərsiniz.
          </div>
        </div>
        <div className="btnGroup">
          {/* <div className="addBtn">
            <button className="btnAdd" type="button" onClick={addNewInp}>
              <img src={addCircle} alt="Add Circle" />
              Add new
            </button>
          </div> */}
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
              onCancel();
            }}
          >
            İmtina
          </button>
          <button className="btn" onClick={onSubmit}>
            Yadda saxla
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkillsEdit;
