import React from "react";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const FreeTrial = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="freeTrialCard">
      <h4 className="cardTitle">{t("freeTrialCard.trial")}</h4>
      <p className="cardLimit">{t("freeTrialCard.limit")}</p>
      <span className="division"></span>
      <p className="desc">{t("freeTrialCard.description")}</p>
      <Button className="cardBtn" onClick={onClick}>
        {t("freeTrialCard.buttonTitle")}
      </Button>
    </div>
  );
};

export default FreeTrial;
