import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { toastMsg } from "../../../../utils/swal";
import MapAutocomplete from "./MapAutocomplete";
import { $api } from "../../../../api/api";
import axios from "axios";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const isDevelop = process.env.NODE_ENV === "development";

const libraries = ["places"];

const MapComponent = ({ onAddressSelect, value, cordinates, onClickBack }) => {
  const inputRef = useRef();
  const [map, setMap] = useState(null);
  const [place, setPlace] = useState(value);
  const [center, setCenter] = useState({
    lat: cordinates?.lat || null,
    lng: cordinates?.lng || null,
  });
  const [geocoding, setGeocoding] = useState({
    lat: cordinates?.lat || null,
    lng: cordinates?.lng || null,
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: isDevelop
      ? $api("google_map_develop")
      : $api("google_map_production"),
    libraries,
  });

  const onLoad = useCallback(
    (mapInstance) => {
      if (center.lat !== null && center.lng !== null) {
        const bounds = new window.google.maps.LatLngBounds(center);
        mapInstance.fitBounds(bounds);
      }
      setMap(mapInstance);
    },
    [center]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(newCenter);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toastMsg("Kullanıcı konum iznini reddetti.");
              break;
            case error.POSITION_UNAVAILABLE:
              toastMsg("Konum bilgisi mevcut değil.");
              break;
            case error.TIMEOUT:
              toastMsg("İstek zaman aşımına uğradı.");
              break;
            case error.UNKNOWN_ERROR:
              toastMsg("Bilinmeyen bir hata oluştu.");
              break;
          }
        }
      );
    } else {
      toastMsg("Tarayıcınız konum hizmetlerini desteklemiyor.");
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, []);

  const fetchAdditionalInfo = useCallback(
    async (latitude, longitude) => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              latlng: `${latitude},${longitude}`,
              key: isDevelop
                ? $api("google_map_develop")
                : $api("google_map_production"),
            },
          }
        );
        const locationInfo = response.data;
        if (locationInfo.results && locationInfo.results.length > 0) {
          const { geometry, address_components, formatted_address } =
            locationInfo?.results[0];
          const longitude = geometry?.location?.lng.toFixed(6);
          const latitude = geometry?.location?.lat.toFixed(6);

          const titles = {
            country: "",
            city: "",
            others: "",
          };
          address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              titles.city = component.long_name;
            } else if (component.types.includes("country")) {
              titles.country = component.long_name;
            } else {
              titles.others = component.long_name;
            }
          });

          const cityFromAddress = formatted_address.replace(/[\s,]+/g, "");
          setPlace(formatted_address);

          onAddressSelect({
            formatted_address,
            city: titles.city || cityFromAddress,
            country: titles.country,
            latitude,
            longitude,
          });
        } else {
          console.log("Yer bulunamadı.");
        }
      } catch (error) {
        console.error("API çağrısında hata:", error);
      }
    },
    [onAddressSelect, isDevelop]
  );

  useEffect(() => {
    if (geocoding && geocoding.lat && geocoding.lng) {
      fetchAdditionalInfo(geocoding.lat, geocoding.lng);
    }
  }, [geocoding]);

  const handleMapClick = useCallback((e) => {
    const newLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setCenter(newLocation);
    setGeocoding(newLocation);
  }, []);

  const handleTouchEnd = useCallback((e) => {
    const lat = e.latLng?.lat();
    const lng = e.latLng?.lng();
    if (lat && lng) {
      const newLocation = { lat, lng };
      setCenter(newLocation);
      setGeocoding(newLocation);
    }
  }, []);

  const handleMarkerDragEnd = useCallback((e) => {
    const newLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setCenter(newLocation);
    setGeocoding(newLocation);
  }, []);

  return isLoaded && center.lat !== null && center.lng !== null ? (
    <div key={isLoaded}>
      <MapAutocomplete
        style={{ marginBottom: 16 }}
        onAddressSelected={(selected) => {
          const { formatted_address, city, country, url, latitude, longitude } =
            selected;
          const newLocation = {
            lat: Number(latitude),
            lng: Number(longitude),
          };
          setCenter(newLocation);
          onAddressSelect({
            address: formatted_address,
            city,
            country,
            url,
            latitude,
            longitude,
          });
        }}
        value={value}
        geocoding={geocoding}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleMapClick}
        onTouchEnd={handleTouchEnd}
        options={{
          gestureHandling: "greedy",
          disableDoubleClickZoom: true,
        }}
      >
        <Marker
          draggable={true}
          position={center}
          onDragEnd={handleMarkerDragEnd}
        />
      </GoogleMap>
      <button
        style={{ marginTop: 16 }}
        className="primaryBtn"
        onClick={onClickBack}
      >
        Geri
      </button>
    </div>
  ) : (
    <div>Xəritə hazırlanır...</div>
  );
};

export default React.memo(MapComponent);
