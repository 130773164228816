import React from "react";
import CardVisa from "././../../../assets/images/icons/visaSvg.svg";
import MasterCard from "././../../../assets/images/icons/MASTERsvg.svg";
import Export from "././../../../assets/images/icons/exportSvg.svg";

const Payments = () => {
  return (
    <section className="payments">
      <div className="contanier">
        <div className="row">
          <p className="title">Payments</p>
          <div className="paymentComp">
            <div className="card">
              <div className="cardImg">
                <img src={CardVisa} alt="" />
              </div>
              <div className="cardTitle">
                <p className="cardTime">
                  Visa Ending :<span>xxxx xxxx xxxx 0098</span>
                </p>
                <p className="expiresDay">
                  Expires :<span>10/25</span>
                </p>
                <p className="cardName">
                  Name of Card :<span className="name">Asmar Rahimova</span>
                </p>
                <p className="cardAddress">
                  Billing Address :<span>YASAMAL R .BAKU AZ.az1100 baku</span>
                </p>
                <button className="deleteBtn">Delete</button>
              </div>
            </div>
            <span className="line"></span>
            <div className="card">
              <div className="cardImg">
                <img src={MasterCard} alt="" />
              </div>
              <div className="cardTitle">
                <p className="cardTime">
                  Visa Ending :<span>xxxx xxxx xxxx 0098</span>
                </p>
                <p className="expiresDay">
                  Expires :<span>10/25</span>
                </p>
                <p className="cardName">
                  Name of Card :<span className="name">Asmar Rahimova</span>
                </p>
                <p className="cardAddress">
                  Billing Address :<span>YASAMAL R .BAKU AZ.az1100 baku</span>
                </p>
                <button className="deleteBtn">Delete</button>
              </div>
            </div>
            <div className="paymentHistory">
              <div className="paymentTop">
                <h2>Payment History</h2>
                <button>
                  Export
                  <img src={Export} alt="" />
                </button>
              </div>
              <div className="paymentBottom">
                <div className="paymentInfo">
                  <span className="order">Order#</span>
                  <span className="data">Payment data</span>
                  <span className="amount">
                    Amount
                  </span>
                  <span className="status">Status</span>
                  <span className="method">
                    Payment Method
                  </span>
                </div>
                <div className="paymentText">
                  <span className="ordered">1234556789</span>
                  <span className="paymentData">12.02.24</span>
                  <span className="price">$234</span>
                  <button className="statusText">succesful</button>
                  <span className="payMethod">
                  xxxx xxxx xxxx 1622
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payments;
