import {useContext} from "react";
import {Link} from "react-router-dom";
import {MainContext} from "../../../utils/MainContext";

const UserGuide = () => {
  // Global states
  const { setDashboardPage } = useContext(MainContext);

  return (
    <div className="userGuide">
      <h2 className="title">Cooming soon...</h2>
      <Link to="/dashboard" onClick={() => setDashboardPage("boxes")}>
        Go back
      </Link>
    </div>
  );
};

export default UserGuide;
