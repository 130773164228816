import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import leftArrow from "../../../assets/images/icons/arrow-circle-left.svg";
import searchIcon from "../../../assets/images/icons/search-icon.svg";
import DashboardHeader from "../../../components/DashboardHeader";
import Loading from "../../../components/Loading";
import ProfileCard from "../../../components/ProfileCard";
import SearchToBoxModal from "../../../components/SearchToBoxModal";
import SharedProfileModal from "../../../components/SharedProfileModal";
import { MainContext } from "../../../utils/MainContext";
import * as SearchService from "../Service/SearchService";
import * as DashboardService from "../../Dashboard/Service/DashboardService";
import { confirmSwal } from "../../../utils/swal";
import { LoginContext } from "../../../utils/Auth";

const SearchResult = () => {
  // Translation
  const { t } = useTranslation();

  // Router (Params)
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchedParam = searchParams.get("filter");

  // Global states
  const { setSearchToBoxModalOpen } = useContext(MainContext);
  const { user } = useContext(LoginContext);

  // Local states
  const [data, setData] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [searchValue, setSearchValue] = useState(searchedParam);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(searchValue.length === 0 || searchValue === "")) {
      navigate(`/search-result?filter=${searchValue}`);
      window.location.reload();
    }
  };

  useEffect(() => {
    const getResults = async () => {
      setLoading(true);
      try {
        const res = await SearchService.searchByKeyword({
          ...pagination,
          keyword: searchedParam,
        });
        const saveRes = await SearchService.saveSearch({
          query: searchedParam,
        });
        setData(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getResults();
  }, []);

  const deleteProfileFromBox = async (boxId, profileId) => {
    const body = { profile: profileId };
    setLoading(true);
    try {
      await DashboardService.removeProfileInBox(boxId, body);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const removeUserFromBox = (boxId, profileId) => {
    confirmSwal(
      "İstifadəçi silmək",
      "İstifadəçi qutudan silinsin ?",
      t("allBoxes.grant"),
      t("allBoxes.deny"),
      () => {},
      () => deleteProfileFromBox(boxId, profileId),
      "İstifadəçi qutudan silindi",
      "İsitfadəçini silməkdən imtina etdiniz"
    );
  };

  return (
    <>
      {loading && <Loading />}
      <DashboardHeader />
      <main>
        <section className="searchResult">
          <div className="companies">
            <div className="topBar">
              <div className="info">
                <div className="boxName">
                  <p className="goBack" onClick={() => navigate(-1)}>
                    <img src={leftArrow} alt="left-arrow" />
                    <span>{t("searchResult.goBack")}</span>
                  </p>
                  <p className="searchedWord">{searchedParam}</p>
                </div>
                <div className="searchInfo">
                  <p className="resultCount">
                    {data.length} {t("searchResult.result")}
                  </p>
                </div>
              </div>
              <form className={`searchInp ${searchValue === "" && "error"}`}>
                <button className="searchIcon" onClick={handleSubmit}>
                  <img src={searchIcon} alt="search-icon" />
                </button>
                <input
                  type="text"
                  name="bucardSearch"
                  id="bucardSearch"
                  placeholder={t("search.placeholder")}
                  className="searchInput"
                  value={searchValue}
                  onChange={handleSearch}
                />
              </form>
            </div>
            <div className="profiles">
              <div className="results">
                {data.length !== 0 ? (
                  data.map((item) => (
                    <ProfileCard
                      inBox={item.in_boxes_by_owner.length !== 0}
                      in_boxes_by_owner={
                        item.in_boxes_by_owner.length !== 0
                          ? item?.in_boxes_by_owner[0].id
                          : ""
                      }
                      data={item}
                      key={item.id}
                      remove={
                        item.in_boxes_by_owner.length !== 0
                          ? () =>
                              removeUserFromBox(
                                item.in_boxes_by_owner[0].id,
                                item.id
                              )
                          : () => {}
                      }
                      add={() => {
                        setSelectedProfile(item);
                        setSearchToBoxModalOpen(true);
                      }}
                    />
                  ))
                ) : (
                  <div className="noResult">
                    <p className="info">
                      <span>{searchedParam}</span> {t("searchResult.noResult")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <SearchToBoxModal data={selectedProfile} />
      <SharedProfileModal data={user} />
    </>
  );
};

export default SearchResult;
