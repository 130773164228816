import React, {useState} from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";

const ProfileFeaturedAdd = ({closeModal, profileData,setProfileData}) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    title: profileData?.title,
    description: profileData?.description,
    url: profileData?.url,
  });

  const buttonDisabled =
    !profileInfo.title ||
    !profileInfo.description ||
    !profileInfo.url;


    const onSubmit = async (e) => {
      e.preventDefault();
      try {
        const res = await ProfileService.userEdit(profileInfo);
        setProfileData((prev) => ({
          ...prev,
          featured_links: [
            ...prev.featured_links,
            {
              id: res?.data?.id,
              title: res?.data?.title,
              description: res?.data?.description,
              url: res?.data?.url,
              image: null,
            }
          ],
        }));
        closeModal();
      } catch (error) {
        console.log("Error",error);
      }
    };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const body = {
  //       featured_links:{
  //         title: profileInfo.title,
  //         description: profileInfo.description,
  //         url: profileInfo.url,

  //       }
  //     };
  //     const res = await ProfileService.userEdit(body);
  //     console.log("Response" , res);
  //     setProfileData((prev) => ({
  //       ...prev,
  //       title: res?.data?.title,
  //       description: res?.data?.description,
  //       url: res?.data?.url,
  //     }));
  //     closeModal();
  //   } catch (error) {
  //     console.log("Error",error);
  //   }
  // };
  return (
    <div className="profileFeaturedAdd">
      <form className="infoEditForm">
        <CustomInput
          label={"Title"}
          placeholder={"title"}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              title: value,
            }));
          }}
          value={profileInfo.title}
        />
        <CustomInput
          label={"Description"}
          placeholder={"description (max. 111 symbol)"}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              description: value,
            }));
          }}
          value={profileInfo.description}
        />
        <CustomInput
          label={"Paste or type a link to an article, file or account."}
          placeholder={"https://"}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              url: value,
            }));
          }}
          value={profileInfo.url}
        />
        <div className="btnGroup">
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cancel
          </button>
          <button className="btn" onClick={onSubmit}>Save</button>
        </div>
      </form>
    </div>
  );
};

export default ProfileFeaturedAdd;
