// React Router Dom
import {Link} from "react-router-dom";

// Translation
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {LoginContext} from "../utils/Auth";
import {PROFILE_TYPES} from "../Modules/Profile/Service/ProfileService";

const UpgradePackSubscribe = () => {
  // Translation
  const { t } = useTranslation();
  const { user } = useContext(LoginContext);

  const checkUserPlan = () => {
    switch (user?.profile?.profile_type) {
      case PROFILE_TYPES.free:
        return "free";
      case PROFILE_TYPES.personal:
        return "personal";
      case PROFILE_TYPES.business:
        return "business";
    }
  };

  return (
    <div className="upgradePackSubscribe">
      <div className="row">
        <p className="text" style={{ paddingTop: 10, paddingBottom: 10 }}>
          {t("upgradePackSubscribe.sentenceHead")}{" "}
          <Link to="">{t(`upgradePackSubscribe.${checkUserPlan()}`)}</Link>{" "}
          {t("upgradePackSubscribe.sentenceBody")}
        </p>
      </div>
    </div>
  );
};

export default UpgradePackSubscribe;
