import { useContext, useState } from "react";

// Router
import { Link } from "react-router-dom";

// Components
import Loading from "./Loading";

// Images
import boxUsers from "../assets/images/icons/box-users.svg";
import menuDots from "../assets/images/icons/menu-dots.svg";
import buildingIcon from "../assets/images/icons/buildingIcon.svg";
import trashIcon from "../assets/images/icons/trash.svg";

// Context
import { MainContext } from "../utils/MainContext";

// Swal
import { confirmSwal } from "../utils/swal";

// Translation
import { useTranslation } from "react-i18next";

import * as DashboardService from "../Modules/Dashboard/Service/DashboardService";

const Box = ({ data, openBoxMenu, openBoxId, setOpenBoxId }) => {
  // Translation
  const { t } = useTranslation();

  // Global states
  const { setEditBoxOpen, setSelectedBox, setEditBoxId, boxes, setBoxes } =
    useContext(MainContext);

  // Local states
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Delete selected box
  const deleteBox = (boxId) => {
    confirmSwal(
      t("allBoxes.deleteQuestion"),
      t("allBoxes.deleteInfo"),
      t("allBoxes.grant"),
      t("allBoxes.deny"),
      setOpenBoxId,
      async () => {
        setLoading(true);
        setOpenBoxId(null);
        try {
          await DashboardService.deleteBox(boxId);
          const updated = boxes.filter((item) => item.id !== boxId);
          setBoxes(updated);
        } catch (err) {
          setErrorMessage("Error on delete box");
        } finally {
          setLoading(false);
        }
      },
      t("confirmModal.grant"),
      t("confirmModal.deny")
    );
  };

  return (
    <Link
      className="box"
      to={`/box-details/${data.id}`}
      onClick={(e) => {
        setSelectedBox(data);
      }}
    >
      {loading && <Loading />}
      <div className="boxLeft">
        <div className="boxIcon">
          <p className="emoji">{data.icon}</p>
        </div>
        <p className="boxName">{data.title}</p>
      </div>
      <div className="boxRight">
        <div
          className="boxMenu"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            openBoxMenu(data.id);
          }}
        >
          {data.title === "Personals" || data.title === "Companies" ? null : (
            <img src={menuDots} alt="menu-dots" />
          )}
        </div>
        <div className="boxCount">
          <div className="boxUsers">
            <img src={boxUsers} alt="box-users-icon" />
          </div>
          <span className="usersCount">{data.profiles_count}</span>
        </div>
      </div>
      <div
        className={openBoxId === data.id ? "boxOptions isOpen" : "boxOptions"}
        id={data.id}
      >
        <div
          className="option"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setEditBoxOpen(true);
            setEditBoxId(data.id);
            setOpenBoxId(null);
          }}
        >
          <div className="icon">
            <img src={buildingIcon} alt="building-icon" />
          </div>
          <p className="optionInfo">{t("allBoxes.edit")}</p>
        </div>
        <div
          className="option "
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            deleteBox(data.id);
          }}
        >
          <div className="icon">
            <img src={trashIcon} alt="trash-icon" />
          </div>
          <p className="optionInfo">{t("allBoxes.delete")}</p>
        </div>
      </div>
    </Link>
  );
};

export default Box;
