import React, { useState } from "react";
import infoIcon from "../../../../assets/images/icons/info-circle-gray.svg";
import * as ProfileService from "../../Service/ProfileService";
import Spinner from "../../../../components/Spinner";

const ProfileAboutEdit = ({ profileData, closeModal, setProfileData }) => {
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    about: profileData?.about || "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await ProfileService.profileEdit(profileInfo);
      setProfileData((prev) => ({
        ...prev,
        about: profileInfo?.about,
      }));
      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profileInfoEdit">
      <form className="infoEditForm">
        <textarea
          name="about"
          placeholder="Haqqınızda mətn daxil edin"
          className="aboutTextarea"
          onChange={(e) => {
            setProfileInfo((prev) => ({
              ...prev,
              about: e.target.value,
            }));
          }}
          value={profileInfo.about}
          maxLength={1500}
        ></textarea>
        <span className="textCounter">
          {profileInfo.about === null ? 0 : Number(profileInfo.about?.length)} /
          1500
        </span>
        <div className="infoTextArea">
          <img src={infoIcon} alt="" />
          <span>Zəhmət olmasa mətni daxil edin (maksimum 1500 simvol).</span>
        </div>
        <div className="btnGroup">
          <button className="btn" onClick={onSubmit}>
            Yadda saxla
          </button>
          <button
            className="btn cancel"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            İmtina
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileAboutEdit;
