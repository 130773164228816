import React from "react";
import trash from "../../../assets/images/icons/trash.svg";
import addSquare from "../../../assets/images/icons/add-square.svg";

const ContactInfo = () => {
  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <h2>Contact Information</h2>
          <span>View or update your account details</span>
          <div className="contactComponents">
            <div className="contactFullname">
              <div className="fullName input">
                <label htmlFor="fullname">Name Surname</label>
                <input
                  type="text"
                  id="fullname"
                  name="fullname"
                  placeholder="Rufat Bayramov"
                />
              </div>
              <div className="username input">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="rufatbayramovv"
                />
              </div>
            </div>
            <div className="contactNumbers">
              <div className="contactNumberTitle">
                <h2>Numbers</h2>
                <button>Add new</button>
              </div>
              <div className="contactNumberInfo">
                <div className="primaryNumber">
                  <p>Primary*</p>
                  <span>0506826022</span>
                </div>
                <div className="othersNumbers">
                  <p>Others</p>
                  <div className="numberVerified">
                  <span>012 345 67 89</span>
                  <span className="verified">Verified</span>
                  </div>
                  <div className="btnEdit">
                  <button>make primary</button>
                  <button className="trash">
                    <img src={trash} alt="trash-icon" />
                  </button>
                  </div>
           
                </div>
              </div>
            </div>
            <div className="contactNumbers">
              <div className="contactNumberTitle">
                <h2>Emails</h2>
                <button>Add new</button>
              </div>
              <div className="contactNumberInfo">
                <div className="primaryEmail">
                  <p>Primary*</p>
                  <span>rufetbayramov.1905@gmail.com</span>
                </div>
                <div className="othersNumbers">
                  <p>Others</p>
                  <div className="emailVerified">
                  <span>rufetbayramov.1905@gmail.com</span>
                  <span className="verified">Verified</span>
                  </div>
      
                  <div className="btnEdit">
                  <button>make primary</button>
                  <button className="trash">
                    <img src={trash} alt="trash-icon" />
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="socialLinks">
              <h2>Social Profilies</h2>
              <div className="socialLinksComponents">
                <div className="firstRow socialRow">
                  <div className="firstSocialLink">
                    <select name="social" id="" defaultValue="Facebook">
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="github">GitHub</option>
                      <option value="youtube">YouTube</option>
                    </select>
                    <input type="text" placeholder="fb.com/" />
                  </div>
                  <div className="firstSocialLink">
                    <select name="social" id="" defaultValue="Instagram">
                      <option value="instagram">Instagram</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="github">GitHub</option>
                      <option value="youtube">YouTube</option>
                    </select>
                    <input type="text" placeholder="fb.com/" />
                  </div>
                </div>
                <div className="secondRow socialRow">
                  <div className="firstSocialLink">
                    <select name="social" id="" defaultValue="Twitter">
                      <option value="twitter">Twitter</option>
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="github">GitHub</option>
                      <option value="youtube">YouTube</option>
                    </select>
                    <input type="text" placeholder="fb.com/" />
                  </div>
                  <div className="firstSocialLink">
                    <select name="social" id="" defaultValue="Github">
                      <option value="github">GitHub</option>
                      <option value="twitter">Twitter</option>
                      <option value="facebook">Facebook</option>
                      <option value="instagram">Instagram</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="youtube">YouTube</option>
                    </select>
                    <input type="text" placeholder="fb.com/" />
                  </div>
                </div>
              </div>
              <div className="addNewLink">
                <button>
                  <img src={addSquare} alt="add-link" />
                  Add new link
                </button>
              </div>
            </div>
          </div>
          <div className="saveBtn">
            <button>Save Changes</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
