import React from 'react'
import SettingsImg from "../../../assets/images/settingsImg.png"
import SettingsBg from "../../../assets/images/settingsBg.png"
const General = () => {
  return (
    <section className="general">
      <div className="container">
        <div className="row">
          <h2>General Information</h2>
          <span>View or update your account details</span>
          <div className="generalComponents">
            <div className="generalProfilImg">
              <div className="generalImgComponent">
                <div className="generalImgInfo">
                <h3>Profile Picture</h3>
                <button>Change</button>
                </div>
                <div className="profileImg">
                <img src={SettingsImg} alt="" />
                </div>
              </div>
            </div>
            <div className="generalBackground">
              <div className="generalBgInfo">
                <h3>Cover</h3>
                <button>Change</button>
              </div>
              <div className="generalBgImg">
                <img src={SettingsBg} alt="" />
              </div>
            </div>
            <div className="saveBtn">
              <button>Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default General