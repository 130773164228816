import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";

const useRedirectOnPathChange = (isUserIn, setIsUserIn) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redirectPaths = [
    "/",
    "/select-pack",
    "/send-link",
    "/recover-password",
  ];

  const calcExpireDate = () => {
    const tokenDate = JSON.parse(localStorage.getItem("tokenDate"));
    if (tokenDate) {
      const savedDate = dayjs(tokenDate);
      const currentDate = dayjs();
      const differenceInHours = currentDate.diff(savedDate, "hour");
      return differenceInHours;
    }
    return 0;
  };

  const checkTokenValidity = () => {
    const token = localStorage.getItem("X-Authorization");
    const tokenDate = JSON.parse(localStorage.getItem("tokenDate"));
    if (token && tokenDate) {
      const hours = calcExpireDate();
      if (hours >= 40) {
        localStorage.clear();
        setIsUserIn(false);
      } else {
        setIsUserIn(true);
      }
    } else {
      localStorage.clear();
      setIsUserIn(false);
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, []);

  useEffect(() => {
    if (redirectPaths.includes(pathname) && isUserIn) {
      navigate("/dashboard");
    }
  }, [pathname, navigate, redirectPaths]);
};

export default useRedirectOnPathChange;
