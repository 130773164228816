import React from "react";

const CustomInput = ({
  id,
  label,
  onChange,
  name,
  type,
  value,
  placeholder,
  disabled,
  maxLength,
  rightIcon,
  onClickRightIcon,
  defaultValue,
  errorMessage,
}) => {
  return (
    <div className="customInput">
      {rightIcon ? (
        <div className="customInputWithIcon">
          <label htmlFor={id || ""} className={errorMessage ? "error-clr" : ""}>
            {label || ""}
          </label>
          <div className="inputBox">
            <input
              className={
                errorMessage ? "error-clr error-border-clr error-bg-clr" : ""
              }
              onChange={(e) => onChange(e.target.value)}
              name={name || ""}
              id={id || ""}
              type={type || "text"}
              value={value}
              placeholder={placeholder || ""}
              disabled={disabled}
              maxLength={maxLength}
              defaultValue={defaultValue}
            />
            <div
              className={`iconItem ${onClickRightIcon && "clickable"}`}
              onClick={onClickRightIcon ? onClickRightIcon : () => {}}
            >
              {rightIcon && <img src={rightIcon} alt="icon" />}
            </div>
          </div>
        </div>
      ) : (
        <>
          <label htmlFor={id || ""} className={errorMessage ? "error-clr" : ""}>
            {label || ""}
          </label>
          <input
            className={
              errorMessage ? "error-clr error-border-clr error-bg-clr" : ""
            }
            onChange={(e) => onChange(e.target.value)}
            name={name || ""}
            id={id || ""}
            type={type || "text"}
            value={value}
            placeholder={placeholder || ""}
            disabled={disabled}
            maxLength={maxLength}
            defaultValue={defaultValue}
          />
        </>
      )}
      {errorMessage && <span className="errorLabel">{errorMessage}</span>}
    </div>
  );
};

export default CustomInput;
