import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import companies from "../../../db/companies";

const Companies = () => {
  // Translation
  const { t } = useTranslation();

  // Local states
  const [selectedAlphabet, setSelectedAlphabet] = useState("");

  const filteredCompanies = selectedAlphabet
    ? companies.filter((item) => item.title.startsWith(selectedAlphabet))
    : companies;

  // Search for alphabet
  const selectAlphabet = (alphabet) => {
    setSelectedAlphabet(alphabet);
  };

  return (
    <div className="companies">
      <h2 className="sectionTitle">{t("companies.title")}</h2>
      <div className="alphabetBox">
        <ul className="alphabets">
          <li
            className={`title ${selectedAlphabet === null ? "active" : ""}`}
            onClick={() => {
              setSelectedAlphabet(null);
            }}
          >
            {t("companies.alphabetic")}
          </li>
          {companies.map((item) =>
            item.companies.length !== 0 ? (
              <li
                className={`alphabet ${
                  selectedAlphabet === item.title ? "active" : ""
                }`}
                key={item.id}
                onClick={() => selectAlphabet(item.title)}
              >
                {item.title}
              </li>
            ) : null
          )}
        </ul>
      </div>
      <div className="cardBox">
        {filteredCompanies.map((item) =>
          item.companies.length !== 0 ? (
            <ul className="companyCard" key={item.id}>
              <li className="cardTitle">{item.title}</li>
              {selectedAlphabet === null
                ? item.companies.slice(0, 7).map((data) => (
                    <li className="companyName" key={data.id}>
                      <Link to={`/company-page/${data.id}`}>{data.title}</Link>
                    </li>
                  ))
                : item.companies.map((data) => (
                    <li className="companyName" key={data.id}>
                      <Link to={`/company-page/${data.id}`}>{data.title}</Link>
                    </li>
                  ))}
            </ul>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Companies;
