import {useContext, useEffect, useState} from "react";

// Components
import Boxes from "../Modules/Dashboard/View/Boxes";
import Companies from "../Modules/Dashboard/View/Companies";
import UserGuide from "../Modules/Dashboard/View/UserGuide";

// Context
import {MainContext} from "../utils/MainContext";
import UpgradePack from "../Modules/Dashboard/View/UpgradePack";
import Loading from "./Loading";
import * as OrderBucardService from "../Modules/OrderBucard/Service/OrderBucardService";

const DashboardPages = () => {
  // Local states
  const [loading, setLoading] = useState(false);

  // Global states
  const { dashboardPage, setHasCardOrdered } = useContext(MainContext);

  useEffect(() => {
    const checkOrderedCard = async () => {
      setLoading(true);
      try {
        await OrderBucardService.bucardValidateCardOrder();
      } catch (err) {
        if (err.response.data.message) {
          setHasCardOrdered(true);
        }
      } finally {
        setLoading(false);
      }
    };
    checkOrderedCard();
  }, []);


  return (
    <section className="dashboardPages">
      {loading && <Loading />}
      {dashboardPage === "boxes" ? (
        <Boxes />
      ) : dashboardPage === "companies" ? (
        <Companies />
      ) : dashboardPage === "user-guide" ? (
        <UserGuide />
      ) : dashboardPage === "upgrade-pack" ? (
        <UpgradePack />
      ) : null}
    </section>
  );
};

export default DashboardPages;
