import React, { useContext, useEffect, useState } from "react";
// Component
import UpgradePackNumber from "../components/UpgradePackNumber";
import UpgradePackCaption from "../components/UpgradePackCaption";
import UpgradePackPacketArea from "../components/UpgradePackPacketArea";
import UpgradePackInfo from "../components/UpgradePackInfo";
import PacketCard from "../components/PacketCard";
import upgradePack from "../db/upgradePack";
import Loading from "./Loading";
import * as HomeService from "../Modules/Home/Service/HomeService";
import { PROFILE_TYPES } from "../Modules/Profile/Service/ProfileService";
import { LoginContext } from "../utils/Auth";
import BuyPackModal from "./BuyPackModal";
import FreeTrial from "./FreeTrial";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const UpgradeFreePack = () => {
  const { user } = useContext(LoginContext);
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      try {
        const res = await HomeService.planAllList();
        setPlanList(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getPlans();
  }, []);

  const onSubmitFreeTrial = async () => {
    setLoading(true);
    try {
      const res = await HomeService.freeTrial();
      if (res?.trial_end_date) {
        setLoading(false);
        await Swal.fire(t("freeTrialCard.trialStarted"), "", "success").then(
          () => {
            window.location.reload();
          }
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upgradeFreePack">
      {loading && <Loading />}

      <div className="packetArea">
        <UpgradePackCaption />
        <div className="row">
          {planList
            .filter((plan) => plan.profile_type !== "business")
            .map((item) => {
              if (item.profile_type === PROFILE_TYPES.personal) {
                return (
                  <PacketCard
                    id={item.id}
                    data={item}
                    key={item.id}
                    profile_type={user?.profile?.profile_type}
                  />
                );
              } else {
                return;
              }
            })}
          {/* <FreeTrial onClick={onSubmitFreeTrial} /> */}
        </div>
      </div>
      {/* <UpgradePackCaption /> */}
      <UpgradePackPacketArea />
      {/* <UpgradePackCaption /> */}
      {/* <UpgradePackInfo list={upgradePack[0].packInfo} /> */}
      {/* <UpgradePackNumber /> */}
      {/* <UpgradePackInfo list={upgradePack[1].packInfo} /> */}
      <BuyPackModal userId={user?.profile?.id} />
    </div>
  );
};

export default UpgradeFreePack;
