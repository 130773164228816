import {createContext, useEffect, useState} from "react";
import Loading from "../components/Loading";
import * as AuthService from "../Modules/Auth/Service/AuthService";
import useRedirectOnPathChange from "../hooks/useRedirectOnPathChange";

export const LoginContext = createContext();

export const Auth = ({ children }) => {
  const [isUserIn, setIsUserIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  // Custom hook
  useRedirectOnPathChange(isUserIn, setIsUserIn);

  const getUserData = async () => {
    setLoading(true);
    try {
      const res = await AuthService.getUser();
      setUser(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("X-Authorization");
    if (user === null && token) {
      getUserData();
    }
  }, []);

  const globalStates = {
    isUserIn,
    setIsUserIn,
    user,
    setUser,
    loading,
    // Export function
    getUserData,
  };

  return (
    <LoginContext.Provider value={globalStates}>
      {loading && <Loading />}
      {children}
    </LoginContext.Provider>
  );
};
