import * as DashboardProvider from "../Provider/DashboardProvider";

export const getBoxesList = async () => {
  return await DashboardProvider.getBoxes().then((res) => {
    return res.data.data;
  });
};

export const getBox = async (id) => {
  return await DashboardProvider.getBoxDetails(id).then((res) => {
    return res.data.data;
  });
};

export const createBox = async (payload) => {
  return await DashboardProvider.postBoxCreate(payload).then((res) => {
    return res.data.data;
  });
};

export const editBox = async (payload, id) => {
  return await DashboardProvider.putBoxEdit(payload, id).then((res) => {
    return res.data.data;
  });
};

export const deleteBox = async (id) => {
  return await DashboardProvider.deleteBoxWithId(id).then((res) => {
    return res.data;
  });
};

export const addProfileToBox = async (id, body) => {
  return await DashboardProvider.postAddProfileToBox(id, body).then((res) => {
    return res.data;
  });
};

export const removeProfileInBox = async (id, body) => {
  return await DashboardProvider.postDeleteProfileInBox(id, body).then(
    (res) => {
      return res.data;
    }
  );
};

export const getAllBoxModifiedList = async (payload) => {
  const updatedData = [];
  for (const item of payload) {
    const response = await getBox(item.id);
    updatedData.push(response);
  }
  return updatedData;
};

export const moveBetweenBoxes = async (payload) => {
  return await DashboardProvider.postMoveBetweenBoxes(payload).then((res) => {
    return res.data.data.profiles;
  });
};
