import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const NotFound = () => {
  // Translation
  const { t } = useTranslation();

  return (
    <main>
      <section className="notFound">
        <h3 className="infoText">{t("notFound.title")}</h3>
        <Link to="/dashboard">{t("notFound.goBack")}</Link>
      </section>
    </main>
  );
};

export default NotFound;
