import React, { useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import * as ProfileService from "../../Service/ProfileService";

const ProfileCreateExp = ({ closeModal, data, setProfileData }) => {
  const [profileInfo, setProfileInfo] = useState(
    data || {
      title: "",
      start_date: "",
      end_date: "",
      company: "",
      description: "",
      // profession: {
      //   name: "",
      //   description: "",
      // },
      employment_type: "",
      still_working_there: false,
    }
  );

  console.log("profileInfo", profileInfo);

  const button_is_disabled =
    profileInfo.title === "" ||
    profileInfo.start_date === "" ||
    (!profileInfo.still_working_there && profileInfo.end_date === "") ||
    profileInfo.company === "" ||
    profileInfo.description === "" ||
    // profileInfo.profession.name === "" ||
    // profileInfo.profession.description === "" ||
    profileInfo.employment_type === "";

  const handleEmploymentTypeChange = (event) => {
    setProfileInfo((prev) => ({
      ...prev,
      employment_type: event.target.value,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const body = {
        experience: profileInfo.still_working_there
          ? {
              ...(data?.id ? { id: data.id } : {}),
              title: profileInfo.title,
              start_date: profileInfo.start_date,
              company: profileInfo.company,
              description: profileInfo.description,
              employment_type: profileInfo.employment_type,
              still_working_there: profileInfo.still_working_there,
            }
          : profileInfo,
      };

      const res = await ProfileService.profileEdit(body);
      setProfileData((prev) => ({
        ...prev,
        experiences: res?.experiences,
      }));
      closeModal();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <form className="createProfile">
      <div className="experienceComp">
        <div className="experienceInp">
          <CustomInput
            placeholder={"Başlıq"}
            label={"Başlıq"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                title: value,
              }));
            }}
            value={profileInfo.title}
          />
          <CustomInput
            placeholder={"Şirkətin adı"}
            label={"Şirkətin adı"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                company: value,
              }));
            }}
            value={profileInfo.company}
          />
          {/* <CustomInput
            placeholder={"Vəzifənin adı"}
            label={"Vəzifənin adı"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                profession: { ...prev.profession, name: value },
              }));
            }}
            value={profileInfo.profession?.name}
          />
          <CustomInput
            placeholder={"Vəzifənin açıqlaması"}
            label={"Vəzifənin açıqlaması"}
            onChange={(value) => {
              setProfileInfo((prev) => ({
                ...prev,
                profession: { ...prev.profession, description: value },
              }));
            }}
            value={profileInfo.profession?.description}
          /> */}
        </div>
        <div className="experienceInp2">
          <div className="selectInp">
            <label htmlFor="employment-type">İş növü</label>
            <select
              name="employment-type"
              id="employment-type"
              value={profileInfo.employment_type}
              onChange={handleEmploymentTypeChange}
            >
              <option value="" disabled>
                İş növünü seçin...
              </option>
              <option value="full_time">Əsas iş</option>
              <option value="part_time">Əlavə iş</option>
              <option value="internship">Təcrübə</option>
              <option value="contract">Müqavilə üzrə</option>
              <option value="freelance">Sərbəst</option>
              <option value="self_employed">Şəxsi işim</option>
              <option value="volunteer">Könüllü</option>
            </select>
          </div>
          <div className="timeBox">
            <CustomInput
              label={"Başlama tarixi"}
              placeholder={"01.01.1990"}
              // rightIcon={Calendar}
              type={"date"}
              onChange={(value) => {
                setProfileInfo((prev) => ({
                  ...prev,
                  start_date: value,
                }));
              }}
              value={profileInfo.start_date}
            />
            <CustomInput
              label={"Bitmə tarixi"}
              placeholder={"01.01.1995"}
              // rightIcon={Calendar}
              type={"date"}
              disabled={profileInfo.still_working_there}
              onChange={(value) => {
                setProfileInfo((prev) => ({
                  ...prev,
                  end_date: value,
                }));
              }}
              value={profileInfo.end_date}
            />
          </div>
        </div>
        <div className="stillWorking">
          <input
            type="checkbox"
            checked={profileInfo.still_working_there}
            onChange={(e) =>
              setProfileInfo((prev) => ({
                ...prev,
                still_working_there: e.target.checked,
                end_date: "",
              }))
            }
          />
          <p>Hal-hazırda burada çalışıram</p>
        </div>
        <CustomInput
          label={"Əlavə məlumat"}
          placeholder={"Əlavə məlumat"}
          // rightIcon={Calendar}
          onChange={(value) => {
            setProfileInfo((prev) => ({
              ...prev,
              description: value,
            }));
          }}
          value={profileInfo.description}
        />
      </div>
      <div className="btnGroup">
        <button
          className="cancel btn"
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          İmtina
        </button>
        <button
          className="saveBtn btn"
          onClick={onSubmit}
          disabled={button_is_disabled}
        >
          Yadda saxla
        </button>
      </div>
    </form>
  );
};

export default ProfileCreateExp;
